import * as ACTIONS from '../../store/actions/actions'

// third
import { Controller, useForm } from 'react-hook-form'
import { Dialog, Menu, Transition } from '@headlessui/react'
import React, { useEffect } from 'react'

import { BsFillTrashFill } from 'react-icons/bs'
import Bt from '../../components/Bt'
// Store
import { Context } from '../../store/Store'
import InputText from '../../components/InputText'
// K
import KError from '../../components/KError'
import { Link } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import Title from '../../components/Title'
import axios from 'axios'

var _ = require('lodash')

export function BiobreizhFormOrganochlores() {
  const { app, dispatch } = React.useContext(Context)
  const [selectedParcelles, setSelectedParcelles] = React.useState([])
  const [loadingSubmit, setloadingSubmit] = React.useState(false)
  const [hasSubmited, setHasSubmited] = React.useState(false)
  const [choiceParcelle, setChoiceParcelle] = React.useState(false)
  const [delParcelle, setDelParcelle] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [error, setError] = React.useState(null)
  const { control, register, handleSubmit, getValues, reset } = useForm()

  const handleSelectedParcelles = (parcelle) => {
    let newSelectedParcelles = [...selectedParcelles]
    newSelectedParcelles.push(parcelle)
    setSelectedParcelles(newSelectedParcelles)
    setChoiceParcelle(false)
  }

  const onSubmit = (data) => {
    setloadingSubmit(true)
    let a = {
      selectedParcelles: selectedParcelles,
      producteurId: app.profil.certificat,
      nom_prenom: app.profil.nom_prenom,
      adresse: app.profil.adresse,
      CP: app.profil.code_postal,
      portable: app.profil.numero_de_telephone_portable,
      mail: app.profil.adresse_mail,
      producteur: app.profil.nom_de_la_ferme,
      producteur_adresse: `${app.profil.adresse} ${app.profil.code_postal} ${app.profil.ville}`,
    }
    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/organochlores/`,
        a
      )
      .then(function (response) {
        if (response.status === 200) {
          setloadingSubmit(false)
          setHasSubmited(true)
        } else {
          setloadingSubmit(false)
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        setloadingSubmit(false)
        console.log(error)
      })
  }

  const RenderItemParcelle = () => {
    return (
      <>
        {selectedParcelles.length > 0 &&
          selectedParcelles.map((parcelle) => {
            return (
              <div className="relative mt-6 p-4 rounded-lg bg-gray-200 w-full py-6 flex justify-between items-center">
                <div>
                  <h4 className="text-xl mb-0">{parcelle.parcelle}</h4>
                  {parcelle.surface && (
                    <span className="text-xs">{parcelle.surface} Ha</span>
                  )}
                  {parcelle.culture && (
                    <span className="text-xs"> / {parcelle.culture} </span>
                  )}
                </div>
                <div
                  className="absolute -top-8 -right-2 cursor-pointer inline-flex items-center p-1 border border-transparent font-medium rounded-full shadow-sm text-white bg-red-700 hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base my-6"
                  onClick={() => {
                    setDelParcelle(parcelle)
                    setIsOpen(!isOpen)
                  }}
                >
                  <BsFillTrashFill size=".7em" />
                </div>
              </div>
            )
          })}
      </>
    )
  }

  const renderFormParcelle = () => {
    return (
      <>
        <div className="border-gray-200 pt-5">
          <InputText
            type="text"
            label="Nom de la parcelle"
            id="parcelle"
            name="parcelle"
            classCustom="w-full"
            r={{ ...register('parcelle', { required: true }) }}
          />
        </div>

        <div className="border-gray-200 pt-5">
          <InputText
            type="text"
            label="Numéro d'îlot"
            id="num_ilot"
            name="num_ilot"
            classCustom="w-full"
            r={{ ...register('num_ilot', { required: true }) }}
          />
        </div>

        <div className="border-gray-200 pt-5">
          <InputText
            type="text"
            label="Commune"
            id="commune"
            name="commune"
            classCustom="w-full"
            r={{ ...register('commune', { required: true }) }}
          />
        </div>

        <div className="border-gray-200 pt-5">
          <InputText
            type="text"
            label="Surface en Hectare"
            id="surface"
            name="surface"
            classCustom="w-full"
            r={{ ...register('surface', { required: true }) }}
          />
        </div>

        <div className="border-gray-200 pt-5">
          <InputText
            type="text"
            label="Cultures emblavées"
            id="culture"
            name="culture"
            classCustom="w-full"
            r={{ ...register('culture', { required: true }) }}
          />
        </div>

        <div className=" pt-5">
          {!loadingSubmit ? (
            <button
              className="mt-6 inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
              type="button"
              onClick={() => {
                const values = getValues()
                handleSelectedParcelles(values)
                reset({ parcelle: '', surface: '', culture: '' })
              }}
            >
              Ajouter
            </button>
          ) : (
            <div>Patienter...</div>
          )}
        </div>
      </>
    )
  }

  const renderChoiceLegume = () => {
    if (choiceParcelle) {
      return (
        <div className="bg-white p-6 ">
          <div className="flex justify-between">
            <Title classCustom="mb-6" title="Ajouter une parcelle" />
            <span
              className="cursor-pointer"
              onClick={() => setChoiceParcelle(false)}
            >
              Fermer
            </span>
          </div>
          {renderFormParcelle()}
          {error && <KError>{error}</KError>}
        </div>
      )
    } else {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          {selectedParcelles.length > 0 && <RenderItemParcelle />}
          <div className="flex gap-2">
            <div
              className="w-56 cursor-pointer inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base my-6"
              onClick={() => setChoiceParcelle(!choiceParcelle)}
            >
              <span className="font-[30px] w-4 h-4 rounded-full bg-white flex items-center justify-center text-base mr-2">
                +
              </span>
              Ajouter une parcelle
            </div>

            {!loadingSubmit ? (
              <Bt title="Envoyer" type="submit" />
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                <Oval type="Oval" color="#48BFAD" height={100} width={100} />
              </div>
            )}
          </div>
          <hr />

          <div className="py-4">
            <h5 className="text-xl font-extrabold tracking-tight mb-2">
              Rappel des consignes
            </h5>
            <ul className="list-inside list-disc pl-2">
              <li>
                Veuillez déclarer l’ensemble des parcelles qui seront emblavées
                en {new Date().getFullYear()} en Cucurbitacées : courgettes,
                courges, concombre, cornichon, melon, pastèque, chayotte ….{' '}
              </li>
              <li>
                Toutes les parcelles de l’année doivent être déclarées à la
                coop, et ce même si vous pensez qu’une analyse de sol a déjà été
                effectuée sur cette parcelle par le passé
              </li>
              <li>N’oubliez pas les parcelles destinées à la vente directe </li>
              <li>
                Anticipez au maximum avant la plantation ou semis afin de
                pouvoir réagir si le résultat de l’analyse présentait un risque.
              </li>
              <li>
                Des analyses libératoires sur produit pourront être déclenchées
                durant la campagne. Pour CARREFOUR elles sont même
                indispensables à toute commercialisation.
              </li>
              <li>
                RAPPEL : le non-respect de cette déclaration de parcelle fera
                l’objet d’une non-conformité lors du contrôle Biobreizh pouvant
                entraîner la perte de la mention.
              </li>
            </ul>
          </div>
        </form>
      )
    }
  }

  if (hasSubmited)
    return (
      <div className="m-6">
        <Title title="Formulaire envoyé !" />
        <p>Merci pour cet envoi</p>
      </div>
    )
  else
    return (
      <>
        <div className="m-6">{renderChoiceLegume()}</div>
        <Dialog
          open={isOpen}
          onClose={setIsOpen}
          as="div"
          className={
            'fixed inset-0 z-[1000] flex items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-80'
          }
        >
          <div className="flex flex-col bg-gray-100 w-96 py-8 px-4 text-center">
            <Dialog.Overlay />

            <Dialog.Title className="text-xl font-extrabold tracking-tight">
              Suppression
            </Dialog.Title>
            <Dialog.Description className="m-6">
              Vous-vous supprimer cette parcelle ?
            </Dialog.Description>
            <div className="flex justify-center">
              <button
                className="rounded-full bg-gray-500 text-white px-4 py-2 m-1"
                onClick={() => setIsOpen(false)}
              >
                Annuler
              </button>
              <button
                className="rounded-full bg-red-800 text-white px-4 py-2 m-1"
                onClick={() => {
                  setIsOpen(false)
                  let l = selectedParcelles
                  let newl = _.remove(l, function (item) {
                    return item.parcelle === delParcelle.parcelle
                  })
                }}
              >
                Confirmer
              </button>
            </div>
          </div>
        </Dialog>
      </>
    )
}

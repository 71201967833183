// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import * as ACTIONS from './store/actions/actions'
import axios from 'axios'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAuMZw37vHXyUzl-YJS6PkUCu5Brb3s6rM',
  authDomain: 'biobreizh-1601458534457.firebaseapp.com',
  projectId: 'biobreizh-1601458534457',
  storageBucket: 'biobreizh-1601458534457.appspot.com',
  messagingSenderId: '342052317179',
  appId: '1:342052317179:web:ca883cc1d220faf9e84d5e',
  measurementId: 'G-7MYXJYNH1D',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const fetchToken = (app, dispatch) => {
  if(!isSupported()) alert('Pas de notifications possibles')

  if (app.tokenNotifs) return
  return getToken(messaging, {
    vapidKey:
      'BENJsyJQY4KEfAg4yrZUgZXfy1u9QqnLl-bwpeDEdUoHDRzHZWGNThdUrvMEy6xwI3bcZpMr3FheJstimYQWvv4',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken)
        dispatch(ACTIONS.setTokenNotifs(currentToken))
        let data = { token: currentToken }
        axios
          .post(
            `${process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'}/wp-json/bb/v1/token-notification`,
            data
          )
          .then((response) => {
            console.log(response)
          })
          .catch(() => {
            console.error(`Error`)
          })

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        dispatch(ACTIONS.setTokenNotifs(false))
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      //console.log("firebasini", payload)
      resolve(payload)
    })
  })

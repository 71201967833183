import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// K
import BtClear from "./BtClear";

const InputSearch = ({filterableList, handleFilter, searchField, placeHolder}) => {
  const inputEl = useRef(null);

  const _filterList = (v) => {
    if(v !== null) {
      let updatedList = filterableList
      updatedList = updatedList.filter(function (item) {
        console.log(item)
        return item[searchField].toLowerCase().search(v.toLowerCase()) !== -1
      })
      handleFilter(updatedList)
    } else {
      handleFilter(filterableList)
      inputEl.current.value = ""
    }
  }

  return (
    <div className='flex-1 flex'>
      <label htmlFor='search_field' className='sr-only'>
        Chercher
      </label>
      <div className='relative w-full text-gray-400 focus-within:text-gray-600 radius-md bg-gray-300 px-5 py-2 mb-4 rounded-lg flex'>
        <div className='absolute inset-y-0 left-8 flex items-center pointer-events-none'>
          <svg
            className='h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <input
          onKeyUp={(e) => _filterList(e.target.value)}
          id='search_field'
          name='search_field'
          className='block w-full h-full pl-9 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 text-sm bg-transparent'
          placeholder={placeHolder ? placeHolder : 'Rechercher'}
          ref={inputEl}
          type='text'
        />
        <BtClear handleClick={() => _filterList(null)}/>
      </div>
    </div>
  )
}

InputSearch.propTypes = {
  filterableList: PropTypes.func,
  handleFilter: PropTypes.func,
  searchField: PropTypes.string,
  placeHolder: PropTypes.string
}

export default InputSearch

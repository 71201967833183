import React, { useEffect, useState } from 'react'
import { Context } from '../store/Store'
var _ = require('lodash')

const Stepper = ({ steps, setStep, currentStep }) => {
  const { app, dispatch } = React.useContext(Context)

  return (
    <div className="mt-4 overflow-auto">
      <div className="flex justify-start items-center gap-1">
        {steps.map((step, index) => {
          let isDisabled = true
          if (app.poFormState !== null) {
            isDisabled = _.some(app.poFormState[step.groupName], _.isUndefined)
          }

          if (steps > 2 && index === steps.length - 2) return null
          else
            return (
              <button
                key={`stepper-step-${index}`}
                onClick={() => setStep(index)}
                disabled={isDisabled}
                className={`${
                  currentStep === index ? 'bg-base' : ' bg-base opacity-50'
                } flex flex-col justify-center items-center h-16 md:h-full px-2 md:px-6 xs:py-2 md:py-3 border border-transparent font-medium shadow-sm text-white focus:outline-none`}
              >
                <div className="text-xs md:text-lg whitespace-nowrap">
                  Etape {index + 1}
                </div>
                <div className="text-[10px] md:text-xs">
                  {step.stepperLabel}
                </div>
              </button>
            )
        })}
      </div>
    </div>
  )
}

export default Stepper

import React, { useState, useEffect } from 'react'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from 'react-icons/bs'

import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

var _ = require('lodash')

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
}

export const PoRecapForm = ({ formData, steps }) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const pdfWrapperRef = React.useRef()
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getElementSize = () => {
      if (pdfWrapperRef.current) {
        const { width, height } = pdfWrapperRef.current.getBoundingClientRect()
        setElementSize({ width, height })
      }
    }

    getElementSize()

    const handleResize = () => {
      getElementSize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const getAllAValues = () => {
    let data = []
    for (var property in formData) {
      console.log(property)
      if (property === 'producteur') continue
      data.push({ field: property, value: formData[property] })
    }

    const sortedData = data.sort((a, b) => {
      if (a.field < b.field) return -1
      if (a.field > b.field) return 1
      return 0
    })

    return sortedData
  }

  const previewImage = (file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const imageSrc = URL.createObjectURL(file)
      return <img src={imageSrc} className="max-w-full" />
    } else if (file.type === 'application/pdf') {
      return (
        <div ref={pdfWrapperRef}>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            <Page
              pageNumber={pageNumber}
              width={elementSize.width || undefined}
            />
          </Document>
          <div className="flex justify-between m-4 ">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <BsFillArrowLeftCircleFill size={30} />
            </button>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} sur {numPages || '--'}
            </p>
            <button
              type="button"
              disabled={numPages ? pageNumber >= numPages : true}
              onClick={nextPage}
            >
              <BsFillArrowRightCircleFill size={30} />
            </button>
          </div>
        </div>
      )
    } else {
      console.log('Type de fichier non pris en charge')
    }
  }

  const renderValueField = (field, level) => {
    if (field.value instanceof File) {
      return (
        <div
          className={`${
            level === 0 ? 'grid grid-cols-1 md:grid-cols-6' : ' py-4'
          }`}
        >
          <p className="col-span-1">
            <span className={`${level === 0 ? 'uppercase' : 'font-bold'}`}>
              Nom du fichier :{' '}
            </span>
            {field.value.name.replace(/^.*?__/, '')}
          </p>
          <div className="col-span-5 my-4">{previewImage(field.value)}</div>
        </div>
      )
    } else if (field.value instanceof Date) {
      return (
        <div
          className={`${
            level === 0 ? 'grid grid-cols-1 md:grid-cols-6' : 'flex gap-1 py-4'
          }`}
        >
          <p className="col-span-1">
            <span className={`${level === 0 ? 'uppercase' : 'font-bold'}`}>
              Date :
            </span>
          </p>
          <div className="col-span-5">
            {new Date(field.value).toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </div>
        </div>
      )
    } else if (field.value instanceof Object) {
      let items = []

      for (const key in field.value) {
        //console.log(key, field.value[key])
        items.push(
          renderValueField(
            {
              field: key,
              value: field.value[key],
            },
            1
          )
        )
      }

      return (
        <div
          className={`${
            level === 0 ? 'grid grid-cols-1 md:grid-cols-6' : 'flex py-4'
          }`}
        >
          <p className="col-span-1">
            <span className={`${level === 0 ? 'uppercase' : ''}`}>
              {field.field.replace(/^.*?__/, '')} :
            </span>
          </p>
          <div className="col-span-5 p-4 bg-white divide-y-2">{items}</div>
        </div>
      )
    } else {
      const labelField = _.find(
        steps.flatMap((group) => group.fields), // Appartient à un groupe, donc aplati le tableau
        { name: field.field }
      )
      return (
        <div
          className={`${
            level === 0 ? 'grid grid-cols-1 md:grid-cols-6' : 'flex gap-1 py-4'
          }`}
        >
          <p className="col-span-1">
            <span className={`${level === 0 ? 'uppercase' : 'font-bold'}`}>
              {level === 0 ? (
                <>{field.field.replace(/^.*?__/, '')}</>
              ) : (
                <>{labelField.label} : </>
              )}
            </span>
          </p>
          <div className="col-span-5">{field.value}</div>
        </div>
      )
    }
  }

  return (
    <div className="bg-gray-200 p-4 py-6 rounded-lg">
      <div className="flex gap-6 flex-col divide-y">
        {/* {JSON.stringify(getAllAValues())} */}
        {getAllAValues().map((field) => renderValueField(field, 0))}
      </div>
    </div>
  )
}

import React from 'react'

export function AchatsCatalogue() {
  return (
    <div className='h-screen'>
      <iframe
        src='https://drive.google.com/file/d/17b0B3vSsgK1m9doql0fY973ZXM8LsnpP/preview'
        height='100%'
        width='100%'
        allow='autoplay'
      ></iframe>
    </div>
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'

const InputText = ({
  label,
  id,
  defaultValue,
  name,
  r,
  classCustom,
  placeholder,
  type = 'text',
}) => {
  return (
    <div className={classCustom}>
      <label
        htmlFor="email_address"
        className="block text-sm font-medium text-gray-700"
      >
        {label} <span className="text-red-500">*</span>
      </label>
      <input
        step="0.01"
        type={type}
        name={name}
        placeholder={placeholder}
        id={id}
        {...r}
        defaultValue={defaultValue}
        className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
      />
    </div>
  )
}

InputText.propTypes = {
  classCustom: PropTypes.string,
  id: PropTypes.string,
  r: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InputText

import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { Oval } from 'react-loader-spinner'
import ReactDatePicker from 'react-datepicker'

// Store
import { Context } from '../../store/Store'

var _ = require('lodash')

export function AchatsLegumesList({ handleSelectedLegumes }) {
  const { app, dispatch } = React.useContext(Context)
  const [startDate, setStartDate] = React.useState(new Date())
  const [loadingSubmit, setloadingSubmit] = React.useState(false)
  const [especes, setEspeces] = React.useState(null)
  const [types, setTypes] = React.useState(null)
  const [varietes, setVarietes] = React.useState(null)

  const convertDate = (date) => {
    return (
      date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    )
  }

  const { register, getValues, control, setValue } = useForm({
    defaultValues: {
      unite: "Grammes",
    },
  });

  useEffect(() => {
    let legumes_ha = _.sortBy(app.legumesha, [
      function (o) {
        return o.variete;
      },
    ]);
    let types = _.sortBy(app.legumesha, [
      function (o) {
        return o.type;
      },
    ]);
    let especes = _.sortBy(app.legumesha, [
      function (o) {
        return o.espece;
      },
    ]);

    setEspeces(_.uniq(_.map(especes, "espece")));
    setTypes(_.uniq(_.map(types, "type")));
    setVarietes(legumes_ha);
  }, []);

  const _initLegumesha = () => {
    let legumes_ha = _.sortBy(app.legumesha, [
      function (o) {
        return o.variete;
      },
    ]);
    let types = _.sortBy(app.legumesha, [
      function (o) {
        return o.type;
      },
    ]);
    let especes = _.sortBy(app.legumesha, [
      function (o) {
        return o.espece;
      },
    ]);

    setEspeces(_.uniq(_.map(especes, "espece")));
    setTypes(_.uniq(_.map(types, "type")));
    setVarietes(legumes_ha);
    setValue("espece", { label: "Choisir une espèce", value: "null" }, { shouldValidate: true });
    setValue("type", { label: "Choisir un type", value: "null" }, { shouldValidate: false });
    setValue("variete", { label: "Choisir une variété", value: "null" }, { shouldValidate: true });
  };

  const handleSelectVariete = (selectedOption) => {
    if (!selectedOption) {
      _initLegumesha();
    } else {
      setValue(
        "variete",
        {
          label: selectedOption.value,
          value: selectedOption.value,
        },
        { shouldValidate: true }
      );

      let selectedVariete = app.legumesha.filter((el) => el.variete === selectedOption.value);

      let Especes = _.uniq(_.map(selectedVariete, "espece"));
      setEspeces(Especes);

      let Types = _.uniq(_.map(selectedVariete, "type"));
      setTypes(Types);
    }
  };

  const handleSelectType = (selectedOption) => {
    if (!selectedOption) {
      _initLegumesha();
    } else {
      setValue(
        "type",
        {
          label: selectedOption.value,
          value: selectedOption.value,
        },
        { shouldValidate: true }
      );

      let EspecesByType = app.legumesha.filter((el) => el.type === selectedOption.value);

      let Especes = _.uniq(_.map(EspecesByType, "espece"));
      setEspeces(Especes);

      setVarietes(EspecesByType);
    }
  };

  const handleSelectEspece = (selectedOption) => {
    if (!selectedOption) {
      _initLegumesha();
    } else {
      setValue(
        "espece",
        {
          label: selectedOption.value,
          value: selectedOption.value,
        },
        { shouldValidate: true }
      );
      // Types
      let typesByEspece = app.legumesha.filter((el) => el.espece === selectedOption.value);
      let types = _.uniq(_.map(typesByEspece, "type"));
      setTypes(types);
      setVarietes(typesByEspece);
    }
  };

  if (varietes !== null) {
    return (
      <>
        <div className="border-gray-200 pt-5">
          <label for="cert1" className="mr-3 block text-sm font-medium text-gray-700">
            Espèce <span className="text-red-500">*</span>
          </label>
          <Controller
            control={control}
            label="Espèce"
            name="espece"
            {...register("espece", { required: true })}
            render={({ field }) => (
              <Select
                {...field}
                onChange={handleSelectEspece}
                options={especes.map((espece) => {
                  if (espece !== null) {
                    return { value: espece, label: espece };
                  } else {
                    return { value: "nc", label: "nc" };
                  }
                })}
                //value={defaultEspece}
                isSearchable={true}
                isClearable={true}
                placeholder="Choix de l'espèce"
              />
            )}
          />
        </div>
        <div className="border-gray-200 pt-5">
          <label for="cert1" className="mr-3 block text-sm font-medium text-gray-700">
            Type <span className="text-red-500">*</span>
          </label>
          <Controller
            control={control}
            label="Type"
            name="type"
            {...register("type")}
            render={({ field }) => (
              <Select
                {...field}
                onChange={handleSelectType}
                options={types.map((type) => {
                  if (type !== null) {
                    return { value: type, label: type };
                  } else {
                    return { value: "nc", label: "nc" };
                  }
                })}
                //defaultValue={defaultType.label ? defaultType : 'Indéfini'}
                //value={defaultType.label ? defaultType : 'Indéfini'}
                isSearchable={true}
                isClearable={true}
                placeholder="Choix du type"
              />
            )}
          />
        </div>

        <div className="border-gray-200 pt-5">
          <label for="cert1" className="mr-3 block text-sm font-medium text-gray-700">
            Variété <span className="text-red-500">*</span>
          </label>

          <Controller
            control={control}
            label="Variété"
            name="variete"
            {...register("variete", { required: true })}
            render={({ field }) => (
              <Select
                {...field}
                onChange={handleSelectVariete}
                options={varietes.map((variete) => {
                  if (variete.variete !== null) {
                    return { value: variete.variete, label: variete.variete };
                  } else {
                    return { value: "nc", label: "nc" };
                  }
                })}
                //defaultValue={defaultVariete}
                //value={defaultVariete}
                isSearchable={true}
                isClearable={true}
                placeholder="Choix de la variété"
              />
            )}
          />
        </div>
        <hr className="mt-6 bg-white text-white border-white" />
        <div className="py-5">
          <div className="w-full">
            <label htmlFor="quantite" className="block text-sm font-medium text-gray-700">
              Quantité<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="quantite"
              placeholder="Quantité"
              id="quantite"
              {...register("quantite", { required: true })}
              className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="border-gray-200 pt-5">
          <div className="flex items-center">
            <input
              name="unite"
              type="radio"
              value="Grammes"
              {...register("unite", { required: true })}
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label htmlFor="push_everything" className="ml-3 mr-3 block text-sm font-medium text-gray-700">
              Grammes
            </label>
            <input
              name="unite"
              type="radio"
              value="Kg"
              {...register("unite", { required: true })}
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label htmlFor="push_everything" className="ml-3 mr-3 block text-sm font-medium text-gray-700">
              Kg
            </label>
            <input
              name="unite"
              type="radio"
              value="Graines"
              {...register("unite", { required: true })}
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label htmlFor="push_everything" className="ml-3 mr-3 block text-sm font-medium text-gray-700">
              Graines
            </label>
            <input
              name="unite"
              type="radio"
              value="Plants"
              {...register("unite", { required: true })}
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label htmlFor="push_everything" className="ml-3 mr-3 block text-sm font-medium text-gray-700">
              Plants
            </label>
          </div>
        </div>
        <div className="py-4 mt-4">
          <label for="date" className="block text-sm font-medium text-gray-700">
            Date livraison souhaitée <span className="text-red-500">*</span>
          </label>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <ReactDatePicker
                className="input text-xl"
                placeholderText="Select date"
                locale="fr"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setStartDate(date);
                }}
                selected={startDate}
                inline
              />
            )}
          />
        </div>

        <div className=" pt-5">
          {!loadingSubmit ? (
            <button
              className="mt-6 inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
              type="button"
              onClick={() => {
                const values = getValues();
                handleSelectedLegumes({
                  ...values,
                  date: convertDate(startDate),
                });
              }}
            >
              Ajouter
            </button>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <Oval type="Oval" color="#48BFAD" height={30} width={30} />
              Patientez ...
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Oval type="Oval" color="#48BFAD" height={100} width={100} />
      </div>
    );
  }
}

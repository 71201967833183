// Icones
import { BsArrowBarLeft } from 'react-icons/bs'
// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// BT
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import React from 'react'
// Thirds

export function Price() {
  const { app } = React.useContext(Context)

  return (
    <div className="flex h-full flex-col justify-between">
      <Header
        left={
          <Link
            to="/"
            className="flex justify-center items-center w-7 h-7 rounded-full bg-white"
          >
            <BsArrowBarLeft />
          </Link>
        }
        right=""
        title="Prix"
      />

      <div className="overflow-auto h-full md:pl-64 ">
        <div className="max-w-7xl mx-auto px-6 py-6">
          {app.price && (
            <div>
              <p className="text-sm tracking-tight text-gray-600">
                {app.price.date}
              </p>
              <h2
                className="text-xl font-extrabold tracking-tight text-gray-900"
                dangerouslySetInnerHTML={{
                  __html: app.price.title,
                }}
              ></h2>
              <div
                className="dangerouslySetInnerHTML"
                dangerouslySetInnerHTML={{
                  __html: app.price.content,
                }}
              ></div>
              {app.price?.pdf && (
                <div className="flex flex-col gap-2 rounded-md shadow mt-2">
                  {app.price.pdf.map((p) => {
                    return (
                      <a
                        href={`${
                          process.env.REACT_APP_APOLLO_URL ||
                          'https://extranet.biobreizh.org'
                        }/g/export.php?file=${p.id}`}
                        className="flex-1 items-center justify-center px-5 py-3 border border-transparent font-medium rounded-md text-white bg-base hover:bg-base-light"
                      >
                        {p.name}
                      </a>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  )
}

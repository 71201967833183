import PropTypes from 'prop-types'
import React from 'react'

const Bt = ({ icon, title, classCustom, type = 'button' }) => {
  return (
    <div className="my-5 flex justify-start sm:space-y-0">
      <button
        type={type}
        className="inline-flex items-center px-6 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
      >
        {title}
      </button>
    </div>
  )
}

Bt.propTypes = {
  classCustom: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
}

export default Bt

// Icones
import { BsArrowBarLeft, BsEyeFill, BsPencil } from 'react-icons/bs'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import {
  Link,
  BrowserRouter as Router,
  useLocation,
  useMatch,
} from 'react-router-dom'
import React, { useEffect } from 'react'
import { getNumberOfWeek, getlegumes, useQuery } from '../utils'

// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// BT
import Header from '../components/Header'
import InputSearch from '../components/InputSearch'
// K
import KAlert from '../components/KAlert'
import axios from 'axios'

const Legume = ({ legume, open, children }) => {
  return (
    <div
      className={`rounded-lg border ${
        legume.annonced === null ? 'bg-white' : 'bg-messages-light color-white'
      } border-gray-300 shadow-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-base`}
    >
      <div className="relative px-6 py-2 flex items-center space-x-3">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src="/images/favicon-biobreizh.png"
            alt=""
          />
        </div>
        <div className="flex-1 min-w-0">
          <span className="absolute inset-0" aria-hidden="true"></span>
          <p className="text-sm font-medium text-gray-900">{legume.title}</p>
          {open && <div className="py-2">{children}</div>}
        </div>

        <span className="bg-white hover:bg-white-700 text-black text-sm px-3 py-3  border rounded-full absolute top-2 right-3 z-10 cursor-pointer">
          <BsPencil />
        </span>
      </div>
    </div>
  )
}

export function AnnoncesList() {
  const { app, dispatch } = React.useContext(Context)
  const [legumes, setLegumes] = React.useState(app.legumes)
  const [success, setSuccess] = React.useState()
  const [legumeInfos, setLegumeInfos] = React.useState([])
  const [, setError] = React.useState(null)

  // Routing
  let query = useQuery()

  useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
    }
  }, [query])

  useEffect(() => {
    if (app.legumes) {
      let newLegumes = app.legumes.map((legume) => {
        return { ...legume, show: false }
      })
      setLegumes(newLegumes)
    }
  }, [app.legumes])

  return (
    <div className="max-w-7xl mx-auto px-6">
      {legumes && (
        <div className="mx-auto pb-10 pt-6">
          <InputSearch
            filterableList={app.legumes}
            handleFilter={setLegumes}
            searchField="title"
            placeHolder="Rechercher un légume"
          />

          <div>
            <div className="mt-1 grid grid-cols-1 gap-2 sm:grid-cols-2">
              {legumes &&
                legumes.map((legume, i) => {
                  if (legume.annonced !== null) {
                    return (
                      <Link
                        key={`legumes-${i}`}
                        state={{ legume, mode: 'edit' }}
                        to="annonce"
                      >
                        <Legume legume={legume} />
                      </Link>
                    )
                  } else
                    return (
                      <Link
                        key={`legumes-${i}`}
                        state={{ legume }}
                        to="annonce"
                      >
                        <Legume legume={legume} />
                      </Link>
                    )
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

const Notifications = () => {
  return (
    <div className='w-2 h-2 bg-red-600 rounded-full absolute right-0 -top-2'></div>
  )
}

Notifications.propTypes = {

}

export default Notifications

import { useState, useEffect, useContext } from 'react'
import axios from 'axios'

// Store
import { Context } from '../store/Store'
import * as ACTIONS from '../store/actions/actions'

export function useBiobreizhDatas() {
  const { app, dispatch } = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (app.biobreizh.length === 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
          }/wp-json/bb/v1/biobreizh/${app.profil.certificat}`
        )
        .then(function (response) {
          if (response.status === 200) {
            setLoading(false)
            dispatch(
              ACTIONS.setBiobreizh({
                ...app.biobreizh,
                cc: response.data.biobreizh.cc,
                referentiel: response.data.biobreizh.referentiel.files,
                certificats: response.data.biobreizh.certificats.files,
                documents: response.data.biobreizh.docs,
                forms: response.data.biobreizh.forms,
              })
            )
          } else {
            setLoading(false)
          }
        })
        .catch(function (error) {
          setLoading(false)
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [])

  return [loading]
}

import { Link, Outlet, useNavigate } from 'react-router-dom'

import { BsArrowBarLeft } from 'react-icons/bs'
import Footer from '../../components/Footer'
// K
import Header from '../../components/Header'
import KAlert from '../../components/KAlert'
import React from 'react'
import { useQuery } from '../../utils'

export function LayoutPlanification({ title }) {
  let query = useQuery()
  let navigate = useNavigate()
  const [success, setSuccess] = React.useState()

  React.useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
    }
  })

  return (
    <div className="h-full flex flex-col items-between">
      <Header
        left={
          <div
            onClick={() => navigate(-1)}
            className="flex justify-center items-center w-7 h-7 rounded-full bg-white"
          >
            <BsArrowBarLeft />
          </div>
        }
        title={title}
      />

      <div className="h-full overflow-auto relative z-0 md:pl-64 ">
        {success === 'create' && (
          <div className="max-w-7xl mx-auto px-6 py-6">
            <KAlert>Saisie enregistrée !</KAlert>
          </div>
        )}

        <div className="relative z-0 py-3 flex justify-center  bg-gray-200">
          <Link to={`bilans`}>
            <button
              type="button"
              className="-ml-px relative flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-base focus:border-base"
            >
              Bilans
            </button>
          </Link>
          {/* <Link to={`/planification`}>
            <button
              type="button"
              className="relative flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-base focus:border-base"
            >
              Déclarer
            </button>
          </Link> */}
          <Link to={`infos`}>
            <button
              type="button"
              className="-ml-px relative flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-base focus:border-base"
            >
              Planifs
            </button>
          </Link>
        </div>

        <div className="max-w-7xl mx-auto">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  )
}

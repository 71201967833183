import React from 'react'
import PropTypes from 'prop-types'

import SideMenu from '../components/SideMenu'
import { MenuAlt2Icon } from '@heroicons/react/outline'

// Store
import { Context } from '../store/Store'
import * as ACTIONS from '../store/actions/actions'

import { renderNotification } from '../screens/Home'

const Header = ({ left, right, title, classCustom }) => {
  const { app, dispatch } = React.useContext(Context)
  return (
    <>
      <SideMenu />
      <div className={`md:bg-white bg-annonces shadow md:pl-64 relataive z-30`}>
        <div className='flex justify-between p-3 md:p-6'>
          <div className='md:hidden'>{left}</div>
          <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate text-center w-full'>
            {title}
          </h1>
          <div>
            <button
              type='button'
              className='pl-2 border-l border-gray-200 text-gray-800 focus:outline-none md:hidden'
              onClick={() => dispatch(ACTIONS.setMenuOpen(!app.menuOpen))}
            >
              <span className='sr-only'>Ouvrir</span>
              <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
            </button>

            {app.notifications.length > 0 && (
              <div className=' md:hidden absolute right-2'>
                {' '}
                {renderNotification(app)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  classCustom: PropTypes.string,
  title: PropTypes.any,
  left: PropTypes.element,
  right: PropTypes.element,
}

export default Header

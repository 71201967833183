import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { Link, useNavigate, BrowserRouter as Router } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

import { Context } from '../../store/Store'
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
import boiteSvg from '../../svg/ic-boite.svg'
// Icones
import livreSvg from '../../svg/ic-livre.svg'

export function PoHomePEC() {
  const { app } = React.useContext(Context)
  const [showDescription, setShowDescription] = useState([])
  const [showAides, setShowAides] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const toggleDescription = (index) => {
    setShowDescription((prevShowDescription) => {
      const updatedShowDescription = [...prevShowDescription]
      updatedShowDescription[index] = !updatedShowDescription[index]
      return updatedShowDescription
    })
  }

  return (
    <div className="max-w-7xl mx-auto px-6 py-6">
      <div>
        <div className="mb-8">
          <Subtitle title="Projets" />
          <Title
            title={app.poTextes['AIDES'].titre_introduction}
            classCustom={'mb-4'}
          />
          <div
            className="dangerouslySetInnerHTML mb-4"
            dangerouslySetInnerHTML={{
              __html: app.poTextes['AIDES'].texte_introduction,
            }}
          ></div>
        </div>
      </div>
      <div className="p-4 bg-white mb-6" id="aides-individuelles">
        <div className="divide-y">
          <div className="xs:py-2">
            <div className="flex justify-between gap-2">
              <div className="w-full">
                <Subtitle title="Formulaire" />
                <Title title="Aides individuelles" />
              </div>
              <button
                onClick={() => {
                  navigate(
                    '/po/po-home-pec?success=false#aides-individuelles',
                    { replace: true }
                  )
                  setShowAides(showAides === 'ind' ? null : 'ind')
                }}
                className="flex-1 items-center whitespace-nowrap justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md bg-base hover:bg-base-light"
              >
                {showAides === 'ind' ? 'Fermer' : 'Faire une demande'}
              </button>
            </div>
            <div className={`${showAides === 'ind' ? 'block' : 'hidden'}`}>
              <h2 className="font-bold my-8">
                Quels documents dois-je préparer ?
              </h2>

              <div className="md:grid grid-flow-col gap-3 pb-8">
                <div
                  className={` text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={livreSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Date de cloture le 30 novembre
                  </h3>
                </div>
                <div
                  className={` text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={`text-price text-xs uppercase mt-3`}>
                    Vos factures acquittées
                  </h3>
                </div>
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Relevés bancaires
                  </h3>
                </div>
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Photos du matériel dont N° de série
                  </h3>
                </div>
              </div>

              <h2
                className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2`}
              >
                Veuillez selectionner la mesure concernée
              </h2>

              {app.po.map((po, i) => {
                if (po.type === 'Aide individuelle')
                  return (
                    <div key={i} className={`mb-4 rounded-lg bg-mesdocs  `}>
                      <div
                        className={`relative pl-4 ${
                          po.description ? 'pr-16' : 'pr-4'
                        } py-4 flex items-center space-x-3`}
                      >
                        <div className="flex-1 min-w-0">
                          <Link
                            to={`/po/po-form/${encodeURIComponent(
                              po.title
                            )}/${encodeURIComponent(po.numero)}`}
                            state={{ aidetype: 'Aides individuelles' }}
                          >
                            <p className="text-sm font-medium text-white">
                              {po.title}
                            </p>
                          </Link>

                          {showDescription[i] && (
                            <p className="text-white text-xs">
                              {po.description}
                            </p>
                          )}
                        </div>
                        {po.description && (
                          <span
                            className="absolute top-4 right-2 z-10 cursor-pointer"
                            onClick={() => toggleDescription(i)}
                          >
                            <BsFillQuestionCircleFill color="white" />
                          </span>
                        )}
                      </div>
                    </div>
                  )
              })}
            </div>
          </div>
          <div className="xs:py-2" id="aides-collectives">
            <div className="flex justify-between gap-2">
              <div className="w-full">
                <Subtitle title="Formulaire" />
                <Title title="Aides collectives" />
              </div>
              <button
                onClick={() => {
                  navigate('/po/po-home-pec?success=false#aides-collectives', {
                    replace: true,
                  })
                  setShowAides(showAides === 'coll' ? null : 'coll')
                }}
                className="flex-1 items-center whitespace-nowrap justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md bg-base hover:bg-base-light"
              >
                {showAides === 'coll' ? 'Fermer' : 'Faire une demande'}
              </button>
            </div>

            <div className={`${showAides === 'coll' ? 'block' : 'hidden'}`}>
              <h2 className="font-bold my-8">
                Quels documents dois-je impérativement préparer 
              </h2>
              <div className="md:grid grid-flow-col gap-3 pb-8">
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={livreSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Date de cloture le 30 novembre
                  </h3>
                </div>
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Vos factures acquittées
                  </h3>
                </div>
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Relevés bancaires ou preuves de paiements
                  </h3>
                </div>
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Rapport d’audit
                  </h3>
                </div>
                <div
                  className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-start items-center py-8 px-8`}
                >
                  <img src={boiteSvg} alt="Icône" className="w-8" />
                  <h3 className={` text-price text-xs uppercase mt-3`}>
                    Rapport d’analyse
                  </h3>
                </div>
              </div>

              <h2
                className={`text-l font-bold tracking-tight text-gray-900 mt-6 mb-4 border-b-2 pb-2 max-w-lg`}
              >
                Liste des mesures
              </h2>
              {app.po.map((po, i) => {
                if (po.type === 'Aide collective')
                  return (
                    <div key={i} className={`mb-4 rounded-lg  bg-mesdocs  `}>
                      <div
                        className={`relative pl-4 ${
                          po.description ? 'pr-16' : 'pr-4'
                        } py-4 flex items-center space-x-3`}
                      >
                        <div className="flex-1 min-w-0">
                          <Link
                            to={`/po/po-form/${encodeURIComponent(
                              po.title
                            )}/${encodeURIComponent(po.numero)}`}
                            state={{ aidetype: 'Aides collectives' }}
                          >
                            <p className="text-white text-sm font-medium ">
                              {po.title}
                            </p>
                          </Link>

                          {showDescription[i] && (
                            <p className="text-white text-xs">
                              {po.description}
                            </p>
                          )}
                        </div>
                        {po.description && (
                          <span
                            className="absolute top-4 right-2 z-10 cursor-pointer"
                            onClick={() => toggleDescription(i)}
                          >
                            <BsFillQuestionCircleFill color="white" />
                          </span>
                        )}
                      </div>
                    </div>
                  )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div>
          <Subtitle title="Autres" />
          <Title
            title={app.poTextes['AIDES'].titre_conclusion}
            classCustom={'mb-4'}
          />
          <div
            className="dangerouslySetInnerHTML mb-4"
            dangerouslySetInnerHTML={{
              __html: app.poTextes['AIDES'].texte_conclusion,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

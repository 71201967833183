import { BsFillQuestionCircleFill, BsInfoCircleFill } from 'react-icons/bs'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

import BtBig from '../../components/BtBig'
import { Context } from '../../store/Store'
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
import { Transition } from '@headlessui/react'
import boiteSvg from '../../svg/ic-boite.svg'
// Icones
import livreSvg from '../../svg/ic-livre.svg'

export function PoHomePAV() {
  const { app } = React.useContext(Context)
  const [showDescription, setShowDescription] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const toggleDescription = (index) => {
    setShowDescription((prevShowDescription) => {
      const updatedShowDescription = [...prevShowDescription]
      updatedShowDescription[index] = !updatedShowDescription[index]
      return updatedShowDescription
    })
  }

  return (
    <div className="max-w-7xl mx-auto px-6 py-6">
      <div>
        <div>
          <Subtitle title="Aides" />
          <Title
            title={app.poTextes['PROJET'].titre_introduction}
            classCustom={'mb-4'}
          />
          <div
            className="dangerouslySetInnerHTML mb-4"
            dangerouslySetInnerHTML={{
              __html: app.poTextes['PROJET'].texte_introduction,
            }}
          ></div>
        </div>
      </div>
      <div className="p-4 bg-white mb-6">
        <div className="grid xs:grid-cols-1 lg:grid-cols-1">
          <div className="xs:p-2">
            <Subtitle title="Formulaire" />
            <Title title="Demande" />

            <h2 className="font-bold my-4">
              Quels documents dois-je impérativement préparer 
            </h2>
            <div className="md:grid xs:grid-cols-3 lg:grid-cols-3 gap-3 mb-8 border-b-2 pb-8">
              <div
                className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-8 px-8`}
              >
                <img src={livreSvg} alt="Icône" className="w-16" />
                <h3 className={` text-price text-xs uppercase mt-3`}>
                  Date de cloture le 30 novembre
                </h3>
              </div>
              <div
                className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-8 px-8`}
              >
                <img src={boiteSvg} alt="Icône" className="w-16" />
                <h3 className={`text-price text-xs uppercase mt-3`}>
                  Vos devis
                </h3>
              </div>
              <div
                className={`text-center rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-8 px-8`}
              >
                <img src={boiteSvg} alt="Icône" className="w-16" />
                <h3 className={`text-price text-xs uppercase mt-3`}>
                  Vos autres devis si hors liste
                </h3>
              </div>
            </div>

            <h2
              className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2`}
            >
              Veuillez selectionner la mesure concernée
            </h2>

            {app.po.map((po, i) => {
              if (po.type === 'Aide individuelle')
                return (
                  <div key={i} className={`mb-4 rounded-lg  bg-mesdocs  `}>
                    <div
                      className={`relative pl-4 ${
                        po.description ? 'pr-16' : 'pr-4'
                      } py-4 flex items-center space-x-3`}
                    >
                      <div className="flex-1 min-w-0">
                        <Link
                          to={`/po/po-form/${encodeURIComponent(
                            po.title
                          )}/${encodeURIComponent(po.numero)}`}
                          state={{ aidetype: 'Aides à venir' }}
                        >
                          <p className="text-sm font-medium text-white ">
                            {po.title}
                          </p>
                        </Link>

                        {showDescription[i] && (
                          <p className="text-white text-xs">{po.description}</p>
                        )}
                      </div>
                      {po.description && (
                        <span
                          className="absolute top-4 right-2 z-10 cursor-pointer"
                          onClick={() => toggleDescription(i)}
                        >
                          <BsFillQuestionCircleFill color="white" />
                        </span>
                      )}
                    </div>
                  </div>
                )
            })}
          </div>
        </div>
      </div>
      <div class="mb-8">
        <div>
          <Subtitle title="Autres" />
          <Title
            title={app.poTextes['PROJET'].titre_conclusion}
            classCustom={'mb-4'}
          />
          <div
            className="dangerouslySetInnerHTML mb-4"
            dangerouslySetInnerHTML={{
              __html: app.poTextes['PROJET'].texte_conclusion,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

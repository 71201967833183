import React, { useEffect } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../db'
// Icones
import { BsArrowBarLeft } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
// BT
import Header from '../components/Header'
import * as ACTIONS from '../store/actions/actions'
// Store
import { Context } from '../store/Store'
import { array } from 'prop-types'
var _ = require('lodash')

export function Messages() {
  const { app, dispatch } = React.useContext(Context)
  const notifs = useLiveQuery(() => db.notifs.toArray())

  // DB
  if (notifs) {
    notifs.map(notif => {
      if(notif.subject === 'messages') db.notifs.delete(notif.id)
    })
  }

  useEffect(() => {
    let old = app.notifications
    console.log('app.notifications', app.notifications)
    let newNotifications = _.remove(old, (notif) => {
      return notif.subject !== 'messages'
    })
    console.log('newNotifications', newNotifications)
    dispatch(ACTIONS.setNotifications(newNotifications))

    /*
    const a = []
    const db = new Dexie('bb')
    .open()
    .then(async db => {
      const c = await db.table('notifs').toArray((notif) => {
        console.log('***', notif)
        a.push(notif)
      })

      console.log('################# ' + a)
    })
    .catch(function (e) {
      console.log('Oh uh: ' , e)
    })
    */
  }, [])

  return (
    <div className='flex h-full flex-col justify-between'>
      <Header
        left={
          <Link
            to='/'
            className='flex justify-center items-center w-7 h-7 rounded-full bg-white'
          >
            <BsArrowBarLeft />
          </Link>
        }
        right={<>&nbsp;</>}
        title='Messages'
      />

      <div className=' overflow-auto h-full md:pl-64 '>
        <div className='max-w-7xl mx-auto px-6 py-6'>
          <ul className='divide-y divide-gray-200'>
            {app.messages.map((message, i) => {
              return (
                <li className='py-4' key={`id-${i}`}>
                  <p className='text-sm tracking-tight text-gray-600'>
                    {message.date}
                  </p>
                  <h2
                    className='text-xl font-extrabold tracking-tight text-gray-900'
                    dangerouslySetInnerHTML={{
                      __html: message.title,
                    }}
                  ></h2>

                  <div
                    className='dangerouslySetInnerHTML'
                    dangerouslySetInnerHTML={{
                      __html: message.content,
                    }}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  )
}

import React, { useEffect } from 'react'

import { Oval } from 'react-loader-spinner'

// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

// K
import { useBiobreizhDatas } from '../../hooks/useBiobreizhDatas'
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
import BtBig from '../../components/BtBig'
import bbSvg from '../../svg/bb.svg'
var _ = require('lodash')

export function Biobreizh() {
  const { app } = React.useContext(Context)
  const [ loading ] = useBiobreizhDatas()

  return (
    <div className='mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8'>
      <div className='space-y-4'>
        {!loading ? (
          <div className='w-full bg-white p-6 border-gray-200 rounded-lg'>
            <div className='divide-y divide-gray-200 mb-4'>
              <div className='space-y-1'>
                <Subtitle title='Biobreizh' />
                <Title title='Référentiel' />

                {app.biobreizh?.cc && (
                  <div className='mt-6'>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`${
                        process.env.REACT_APP_APOLLO_URL ||
                        'https://extranet.biobreizh.org'
                      }/g/export.php?file=${app.biobreizh.cc.id}`}
                      className=''
                    >
                      <BtBig
                        icon={bbSvg}
                        title='Cahier des charges'
                        classCustom='border border-1 bg-white px-4 min-h-[180px]'
                        titleColor='text-black'
                      />
                    </a>
                  </div>
                )}

                <div className='py-5 grid gap-4'>
                  {app.biobreizh?.referentiel &&
                    app.biobreizh?.referentiel.map((ref) => {
                      if (!ref) return
                      return (
                        <div className='flex rounded-md shadow mb-2'>
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href={`${
                              process.env.REACT_APP_APOLLO_URL ||
                              'https://extranet.biobreizh.org'
                            }/g/export.php?file=${ref.id}`}
                            className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                          >
                            {ref.name}
                          </a>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center h-screen'>
            <Oval type='Oval' color='#48BFAD' height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  )
}

import Dexie from 'dexie'

export const db = new Dexie('bb')
db.version(1).stores({
  notifs: `
    ++id,
    subject,
    title,
    body,
    new`,
})

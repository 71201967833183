import React from 'react'
import {
  Outlet,
  Link,
  useNavigate
} from 'react-router-dom'
import { BsArrowBarLeft } from 'react-icons/bs'

// K
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useQuery } from '../../utils'
import KAlert from '../../components/KAlert'


export function LayoutCommon({ title }) {
  let query = useQuery()
  let navigate = useNavigate()
  const [success, setSuccess] = React.useState()

  React.useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
    }
  })

  return (
    <div className="h-full flex flex-col items-between">
      <Header
        left={
          <div onClick={() => navigate(-1)} className="flex justify-center items-center w-7 h-7 rounded-full bg-white">
            <BsArrowBarLeft />
          </div>
        }
        title={title}
      />

      <div className="h-full overflow-auto relative z-0  md:pl-64 ">
        {success === "create" && (
          <div className="max-w-7xl mx-auto px-6 py-6">
            <KAlert>Saisie enregistrée !</KAlert>
          </div>
        )}

        <div  className="max-w-7xl mx-auto"><Outlet /></div>
      </div>

      <Footer />
    </div>
  );
}

import React, { useEffect } from 'react'
import axios from 'axios'

import { Oval } from 'react-loader-spinner'
import {
  PhoneOutgoingIcon,
  MailOpenIcon,
  DeviceMobileIcon,
} from '@heroicons/react/outline'

// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

// K
import InputSearch from '../../components/InputSearch'
var _ = require('lodash')

export function Adherents() {
  const { app, dispatch } = React.useContext(Context)
  const [adherents, setAdherents] = React.useState(app.adherents)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (app.adherents.length === 0) {
      axios
        .get(`https://extranet.biobreizh.org/wp-json/bb/v1/adherents/`)
        .then(function (response) {
          if (response.status === 200) {
            setLoading(false)
            setAdherents(response.data.producteur)
            dispatch(ACTIONS.setAdherents(response.data.producteur))
          } else {
            setLoading(false)
          }
        })
        .catch(function (error) {
          setLoading(false)
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8">
      <div className="space-y-4">
        {!loading ? (
          <>
            <InputSearch
              filterableList={app.adherents}
              handleFilter={setAdherents}
              searchField="nom_prenom"
              placeHolder="Rechercher un adhérent par son nom / prénom"
            />

            <ul
              role="list"
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
            >
              {adherents &&
                adherents.map((person) => (
                  <li key={person.name} className="shadow-lg bg-white">
                    <div className="">
                      <div className="aspect-w-3 aspect-h-2 relative">
                        <img
                          className="object-cover shadow-lg rounded-lg w-full h-64"
                          src={person.photo_profil}
                          alt=""
                        />
                        <p className="text-black text-xs absolute top-2 px-2 py-1 flex justify-between items-center w-full text-center">
                          <span className="p-2 bg-white bg-opacity-75">
                            {person.certificat}
                          </span>
                          <a
                            href={person.fichier_pdf_certificat}
                            target="_blank"
                            className="text-[8px] flex items-center inline-block p-1 bg-white bg-opacity-75 hover:bg-mesdocs"
                          >
                            CERTIF.
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                              />
                            </svg>
                          </a>
                        </p>
                      </div>

                      <div className="space-y-2 bg-red-800 spikes p-4 -mt-2">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{person.nom_prenom}</h3>
                          <p className="text-mesdocs text-xs">
                            {person.adresse} {person.code_postal} {person.ville}
                          </p>
                        </div>
                        <ul role="list" className="flex flex-col">
                          {person.numero_de_telephone_fixe && (
                            <li>
                              <a
                                href={`tel:${person.numero_de_telephone_fixe}`}
                                className="text-black hover:text-gray-500 flex items-center"
                              >
                                <DeviceMobileIcon
                                  className="text-black h-4 w-4 mr-2"
                                  aria-hidden="true"
                                />
                                <span className="">
                                  {person.numero_de_telephone_fixe}
                                </span>
                              </a>
                            </li>
                          )}
                          {person.numero_de_telephone_portable && (
                            <li>
                              <a
                                href={`tel:${person.numero_de_telephone_portable}`}
                                className="text-black hover:text-gray-500 flex items-center"
                              >
                                <DeviceMobileIcon
                                  className="text-black h-4 w-4 mr-2"
                                  aria-hidden="true"
                                />
                                <span className="">
                                  {person.numero_de_telephone_portable}
                                </span>
                              </a>
                            </li>
                          )}
                          {person.adresse_mail && (
                            <li>
                              <a
                                href={`mailto:${person.adresse_mail}`}
                                className="text-black hover:text-gray-500 flex items-center"
                              >
                                <MailOpenIcon
                                  className="text-black h-4 w-4 mr-2"
                                  aria-hidden="true"
                                />
                                <span className="">{person.adresse_mail}</span>
                              </a>
                            </li>
                          )}
                        </ul>
                        <div className="py-4">
                          <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div
                          className="text-xs"
                          dangerouslySetInnerHTML={{
                            __html: person.infos_plus,
                          }}
                        />
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center h-screen">
            <Oval type="Oval" color="#48BFAD" height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  )
}

import * as ACTIONS from '../../store/actions/actions'

import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import BtBig from '../../components/BtBig'
import { Context } from '../../store/Store'
import { Review } from './Review'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
import axios from 'axios'
import planificationSvg from '../../svg/planification.svg'

export const Form = () => {
  const [step, setStep] = useState(0)
  const { app, dispatch } = React.useContext(Context)
  const [loadingSubmit, setloadingSubmit] = React.useState(false)
  const [, setError] = React.useState(null)
  let navigate = useNavigate()

  const nextStep = () => setStep((prev) => prev + 1)
  const prevStep = () => setStep((prev) => prev - 1)

  useEffect(() => {
    if (step === 0) dispatch(ACTIONS.setBlsFormState(null))
  }, [step])

  const handleSubmit = () => {
    setloadingSubmit(true)
    let preparedData = {
      ...app.blsFormState.step1,
      choiceLegumes: [...app.blsFormState.step2.legumes],
      producteur: app.profil.certificat,
      producteur_adresse: `${app.profil.adresse} ${app.profil.code_postal} ${app.profil.ville}`,
      producteurId: app.profil.id,
    }

    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/bl2/`,
        preparedData
      )
      .then(function (response) {
        if (response.status === 200) {
          setloadingSubmit(false)
          navigate('/bls?success=create')
          setStep(0)
        } else {
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  switch (step) {
    case 0:
      return (
        <div className="max-w-7xl mx-auto p-6">
          <div>
            <Subtitle title="Envoyer par e-mail" />
            <Title title="Création de bons de livraison" classCustom={'mb-4'} />
          </div>
          <div className="p-4 border-2 my-6">
            <span
              onClick={() => {
                navigate('/bls')
                setStep(1)
              }}
            >
              <BtBig
                icon={planificationSvg}
                title="Nouveau Bl"
                classCustom="border text-center border-1 bg-white  min-h-[180px]"
                titleColor="text-black"
              />
            </span>
          </div>
        </div>
      )
    case 1:
      return <Step1 nextStep={nextStep} />
    case 2:
      return <Step2 nextStep={nextStep} prevStep={prevStep} />
    case 3:
      return <Step3 nextStep={nextStep} prevStep={prevStep} />
    case 4:
      return (
        <Review
          prevStep={prevStep}
          onSubmit={handleSubmit}
          loadingSubmit={loadingSubmit}
        />
      )
    default:
      return null
  }
}

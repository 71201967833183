import { BsFillQuestionCircleFill, BsInfoCircleFill } from 'react-icons/bs'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
import React, { useState } from 'react'

import BtBig from '../../components/BtBig'
import { Context } from '../../store/Store'
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
import { Transition } from '@headlessui/react'
import boiteSvg from '../../svg/ic-boite.svg'
// Icones
import livreSvg from '../../svg/ic-livre.svg'

export function PoArchives() {
  const { app } = React.useContext(Context)
  return (
    <div className="max-w-7xl mx-auto px-6 py-6">
      <div>
        <div>
          <Subtitle title="Aides" />
          <Title title="PO" classCustom={'mb-4'} />
          <p>MES DEMANDES</p>
        </div>
      </div>
      <div className="p-4 border-2 my-6">
        <div className="py-5 grid gap-4">
          <ul className="divide-y divide-gray-200">
            {app.poArchives &&
              app.poArchives.map((doc) => {
                if (!doc) return
                return (
                  <div className="flex rounded-md shadow mb-2">
                    <a
                      target="_blank"
                      href={`https://extranet.biobreizh.org/g/export.php?file=${doc.id}`}
                      className="flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light"
                    >
                      {doc.name}
                    </a>
                  </div>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

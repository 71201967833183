// Icones
import {
  BsArrowBarLeft,
  BsReverseLayoutTextSidebarReverse,
} from 'react-icons/bs'
import { BsToggleOff, BsToggleOn } from 'react-icons/bs'

// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// BT
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import React from 'react'
// K
import Subtitle from '../components/Subtitle'
import Title from '../components/Title'
import { Transition } from '@headlessui/react'

export function Profil() {
  const { app } = React.useContext(Context)
  const [isOpen, setIsOpen] = React.useState(false)

  const renderDatas = (app) => {
    if (!app.profil.second) {
      return (
        <>
          <Title title='Informations personnelles' />
          <div className='flex md:items-center my-8 flex-col md:flex-row'>
            <img
              className='object-cover shadow-lg rounded-lg w-full md:max-w-xs h-64 mb-6 md:mr-8'
              src={app.profil.photo_profil}
              alt=''
            />
            <div className='w-full divide-y divide-gray-200'>
              <div className='divide-y'>
                <div className='py-2 grid gap-4'>
                  <div className='flex justify-between items-center space-x-4 '>
                    <span className=''>{app.profil.title}</span>
                  </div>
                </div>
                <div className='py-2 grid gap-4'>
                  <div className='flex justify-between items-center space-x-4 '>
                    <span className=''>{app.profil.adresse_mail}</span>
                  </div>
                </div>
                <div className='py-2 grid gap-4'>
                  <div className='flex justify-between items-center space-x-4 '>
                    <span className=''>
                      {app.profil.numero_de_telephone_portable}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='my-4' id='docs'>
            <Title title='Mon certificat' />
          </div>
          <div className='divide-y divide-gray-200'>
            {app.profil.certificat_file_docs &&
              app.profil.certificat_file_docs.map((certif) => {
                return (
                  <div className='flex rounded-md shadow mt-2'>
                    <a
                      target='_blank'
                      href={`${
                        process.env.REACT_APP_APOLLO_URL ||
                        'https://extranet.biobreizh.org'
                      }/g/export.php?file=${certif.id}`}
                      className='flex items-center justify-left  px-5 py-3 border border-transparent text-white font-medium rounded-md text-white  hover:bg-mesdocs-light w-full bg-mesdocs'
                    >
                      <BsReverseLayoutTextSidebarReverse />{' '}
                      <span className='px-2'> {certif.name}</span>
                    </a>
                  </div>
                )
              })}
          </div>

          <div className='my-4' id='docs'>
            <Subtitle title='Téléchargement' />
            <Title title='Documents' />
          </div>
          <div className='divide-y divide-gray-200'>
            {app.profil.documents &&
              app.profil.documents.map((doc) => {
                if (!doc.id) return
                return (
                  <div className='flex rounded-md shadow mt-2'>
                    <a
                      target='_blank'
                      href={`${
                        process.env.REACT_APP_APOLLO_URL ||
                        'https://extranet.biobreizh.org'
                      }/g/export.php?file=${doc.id}`}
                      className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                    >
                      {doc.name}
                    </a>
                  </div>
                )
              })}
          </div>
          <div className='my-4 mt-8' id='docs'>
            <div
              className='border-b flex justify-between cursor-pointer'
              onClick={() => setIsOpen(!isOpen)}
            >
              <Title title='Archives' />
              <div>
                {isOpen ? <BsToggleOn size={25} /> : <BsToggleOff size={25} />}
              </div>
            </div>
            <Transition
              show={isOpen}
              enter-active-class='transition-opacity duration-75'
              enter-from-class='opacity-0'
              enter-to-class='opacity-100'
              leave-active-class='transition-opacity duration-150'
              leave-from-class='opacity-100'
              leave-to-class='opacity-0'
            >
              {app.profil.documents_archives &&
                app.profil.documents_archives.map((doc) => {
                  if (!doc.id) return
                  return (
                    <div className='flex rounded-md shadow mt-2'>
                      <a
                        target='_blank'
                        href={`${
                          process.env.REACT_APP_APOLLO_URL ||
                          'https://extranet.biobreizh.org'
                        }/g/export.php?file=${doc.id}`}
                        className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                      >
                        {doc.name}
                      </a>
                    </div>
                  )
                })}
            </Transition>
          </div>
        </>
      )
    } else {
      return <p className='text-red-700'>Vous avez un rôle de "second"</p>
    }
  }

  return (
    <div className='flex h-full flex-col justify-between'>
      <Header
        left={
          <Link
            to='/'
            className='flex justify-center items-center w-7 h-7 rounded-full bg-white'
          >
            <BsArrowBarLeft />
          </Link>
        }
        right=''
        title='Profil'
      />

      <div className=' overflow-auto flex-1 md:pl-64 '>
        <div className='mx-auto px-6 py-6'>
          {app.profil && (
            <main
              className='flex-1 overflow-y-auto focus:outline-none'
              tabindex='0'
            >
              <span
                onClick={() => {
                  window.localStorage.removeItem('user')
                  window.location = '/'
                }}
                className='bg-red-600 text-white px-4 py-2 rounded-md inline-block mb-4 cursor-pointer'
              >
                Se déconnecter
              </span>
              {renderDatas(app)}
            </main>
          )}
        </div>
      </div>

      <Footer />
    </div>
  )
}

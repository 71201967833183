import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ title, classCustom }) => {
  return (
    <h2 className={`text-xl font-extrabold tracking-tight text-gray-900 ${classCustom}`}>{title}</h2>
  )
}

Title.propTypes = {
  classCustom: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default Title

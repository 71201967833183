import { BsFillQuestionCircleFill, BsInfoCircleFill } from 'react-icons/bs'
import { Link, Route, BrowserRouter as Router } from 'react-router-dom'
import React, { useState } from 'react'

import BtBig from '../../components/BtBig'
import { Context } from '../../store/Store'
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
import { Transition } from '@headlessui/react'
import boiteSvg from '../../svg/ic-boite.svg'
// Icones
import livreSvg from '../../svg/ic-livre.svg'

export function PoHome() {
  return (
    <div className="max-w-7xl mx-auto px-6 py-6">
      <div>
        <div>
          <Subtitle title="Accueil" />
          <Title title="Aides" classCustom={'mb-4'} />
        </div>
      </div>
      <div className="p-4 border-2 my-6">
        <div className="grid xs:grid-cols-2 lg:grid-cols-3">
          <div className="border-r-2 p-4">
            <Link to="po-home-pec">
              <BtBig
                icon={livreSvg}
                title={`Aides ${new Date().getFullYear()}`}
                classCustom="bg-gray-200 text-center"
                titleColor="text-black"
              />
            </Link>
          </div>
          <div className="p-4">
            <Link to="po-home-pav">
              <BtBig
                icon={boiteSvg}
                title={`Projet ${new Date().getFullYear() + 1}`}
                classCustom="bg-gray-200 text-center"
                titleColor="text-black"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Link, Outlet, useNavigate } from 'react-router-dom'

import { BsArrowBarLeft } from 'react-icons/bs'
import Footer from '../../components/Footer'
// K
import Header from '../../components/Header'
import KAlert from '../../components/KAlert'
import React from 'react'
import { useQuery } from '../../utils'

export function LayoutForm({ title }) {
  let query = useQuery()
  let navigate = useNavigate()
  const [success, setSuccess] = React.useState()

  React.useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
      const timer = setTimeout(() => {
        setSuccess(null)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [query.get('success')])

  return (
    <div className="h-full flex flex-col items-between " id="top">
      <Header
        left={
          <div
            onClick={() => navigate(-1)}
            className="flex justify-center items-center w-7 h-7 rounded-full bg-annonces"
          >
            <BsArrowBarLeft />
          </div>
        }
        title={title}
      />

      <div className="h-full overflow-auto relative z-0 md:pl-64 ">
        {success === 'create' && (
          <div className="max-w-7xl mx-auto px-6 py-6">
            <KAlert>Saisie enregistrée !</KAlert>
          </div>
        )}

        <div className="max-w-7xl mx-auto">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  )
}

import { Context } from '../../store/Store'
import { Oval } from 'react-loader-spinner'
import React from 'react'
import ReviewLegumeDetails from './ReviewLegumeDetails'
import { StepBottom } from './StepBottom'

export const Review = ({ prevStep, onSubmit, loadingSubmit }) => {
  const { app, dispatch } = React.useContext(Context)
  const form = app.blsFormState

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="pt-4">
        <h2 className={`text-xl font-extrabold tracking-tight text-gray-900`}>
          Vérifications
        </h2>
      </div>

      <div className="py-4">
        <h2
          className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2`}
        >
          Information Produit
        </h2>

        <ReviewLegumeDetails
          bl={app.blsFormState}
          handleDel={() => {
            console.log('')
          }}
        />

        <h2
          className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2`}
        >
          Information client
        </h2>

        <div className="inline-block min-w-full py-2 align-middle">
          <div className="bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <p class="text-sm font-medium text-gray-900 w-full font-bold">
              Infos client
            </p>
            <p>
              {app.blsFormState?.step1.client && (
                <div className="text-gray-900">
                  {app.blsFormState?.step1.client.label}
                </div>
              )}
            </p>
            <p>
              {app.blsFormState?.step1.palette && (
                <div className="text-gray-900">
                  {app.blsFormState?.step1.qty_palette}x{' '}
                  {app.blsFormState?.step1.palette}
                </div>
              )}
            </p>
            <p>
              {app.blsFormState?.step1?.comment && (
                <div className="text-gray-900">
                  Commentaire : {app.blsFormState?.step1.comment}
                </div>
              )}
            </p>
          </div>
        </div>
      </div>

      {loadingSubmit ? (
        <div className="text-center">
          <Oval
            wrapperStyle={{ justifyContent: 'center' }}
            className="m-auto justify-center"
            color="#0F5F3F"
            height={40}
            width={40}
          />{' '}
          Veuillez patienter... l'envoi peut être assez long
        </div>
      ) : (
        <StepBottom
          handlePrev={() => prevStep()}
          handleSubmit={() => onSubmit()}
        />
      )}
    </div>
  )
}

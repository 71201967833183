import React from 'react'
import axios from 'axios'
import { Oval } from 'react-loader-spinner'

// K
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

export function PlanificationInfos() {
  const { app, dispatch } = React.useContext(Context)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (!app.planification.releves) {
      axios
        .get(
          `${
            process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
          }/wp-json/bb/v1/planification_releves/${app.profil.certificat}`
        )
        .then(function (response) {
          if (response.status === 200) {
            dispatch(
              ACTIONS.setPlanification({
                ...app.planification,
                releves: response.data,
              })
            )
            dispatch(ACTIONS.refresh(false))
            setLoading(false)
          } else {
            setError('Erreur de connexion, veuillez vérifier vos identifiants')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [app.planification])

  if (!loading) {
    return (
      <div className="w-full bg-white p-6 border-gray-200 ">
        <div className="divide-y divide-gray-200 mb-4">
          <div className="space-y-1">
            <Subtitle title="Infos" />
            <Title title="Ma planification" />
            <div className="py-5 grid gap-4">
              <ul className="divide-y divide-gray-200">
                {app.planification.releves &&
                  app.planification.releves.map((releve) => {
                    if (!releve) return
                    return (
                      <div className="flex rounded-md shadow mb-2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${
                            process.env.REACT_APP_APOLLO_URL ||
                            'https://extranet.biobreizh.org'
                          }/g/export.php?file=${releve.id}`}
                          className="flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light"
                        >
                          {releve.name}
                        </a>
                      </div>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Oval color="#48BFAD" height={100} width={100} />
      </div>
    )
  }
}

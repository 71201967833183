import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../db'
// Store
import { Context } from '../store/Store'
import * as ACTIONS from '../store/actions/actions'
// Icones
import { BsArrowBarLeft } from 'react-icons/bs'
// BT
import Header from '../components/Header'
import Footer from '../components/Footer'
// Thirds
var _ = require('lodash')

export function News() {
  const { app, dispatch } = React.useContext(Context)
  const notifs = useLiveQuery(() => db.notifs.toArray())

  // DB
  if (notifs) {
    notifs.map(notif => {
      if(notif.subject === 'actus') db.notifs.delete(notif.id)
    })
  }

  useEffect(() => {
    let old = app.notifications
    let newNotifications = _.remove(old, (notif) => { return notif.subject !== 'actus'})
    dispatch(ACTIONS.setNotifications(newNotifications))
  }, [])

  return (
    <div className='flex h-full flex-col justify-between'>
      <Header
        left={
          <Link
            to='/'
            className='flex justify-center items-center w-7 h-7 rounded-full bg-white'
          >
            <BsArrowBarLeft />
          </Link>
        }
        right=''
        title="Actualités"
      />

      <div className='overflow-auto flex-1 md:pl-64 '>
        <div className='max-w-7xl mx-auto px-6 py-10'>
          <main
            className='flex-1 overflow-y-auto focus:outline-none'
            tabindex='0'
          >
            <div className='divide-y divide-gray-200'>
              <div className='space-y-1'>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  Actualités
                </h3>
                <div className='divide-y-4 divide-white space-y-5'>
                {app.news &&
                  app.news.map((actu) => {
                    return (
                      <div className=''>
                        <h3 className='text-xl font-medium text-gray-500 my-5'>
                          {actu.title}
                        </h3>

                        <div
                          className='dangerouslySetInnerHTML'
                          dangerouslySetInnerHTML={{
                            __html: actu.content,
                          }}
                        ></div>
                      </div>
                    )
                  })}
                  </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </div>
  )
}

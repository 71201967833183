import React, { useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

// third
import { useForm, Controller } from 'react-hook-form'
import { Oval } from 'react-loader-spinner'
import { BsFillTrashFill } from "react-icons/bs";
import { Dialog, Menu, Transition } from "@headlessui/react";

// K
import KError from "../../components/KError";
import Title from "../../components/Title";
import Bt from "../../components/Bt";
import { AchatsLegumesList } from "./AchatsLegumesList";
var _ = require("lodash");

export function AchatsList() {
  const { app, dispatch } = React.useContext(Context);
  const [selectedLegumes, setSelectedLegumes] = React.useState([]);
  const [loadingSubmit, setloadingSubmit] = React.useState(false);
  const [hasSubmited, setHasSubmited] = React.useState(false);
  const [choiceLegume, setChoiceLegume] = React.useState(false);
  const [delLegume, setDelLegume] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const [error, setError] = React.useState(null);
  const { control, register, handleSubmit, getValues } = useForm();

  const handleSelectedLegumes = (legumes) => {
    if(!legumes.variete || !legumes.quantite) {
      setError("Veuillez remplir tous les champs")
      return
    }
    let newSelectedLegumes = [...selectedLegumes];
    newSelectedLegumes.push(legumes);
    setSelectedLegumes(newSelectedLegumes);
    setChoiceLegume(false);
  };

  const onSubmit = (data) => {
    setloadingSubmit(true);
    let a = {
      selectedLegumes: selectedLegumes,
      commentaire: data.commentaire,
      producteurId: app.profil.certificat,
      producteur: app.profil.nom_de_la_ferme,
      producteur_adresse: `${app.profil.adresse} ${app.profil.code_postal} ${app.profil.ville}`,
    };
    axios
      .post(`${
        process.env.REACT_APP_APOLLO_URL ||
        'https://extranet.biobreizh.org'
      }/wp-json/bb/v1/legumes_ha/`, a)
      .then(function (response) {
        if (response.status === 200) {
          setloadingSubmit(false);
          setHasSubmited(true);
        } else {
          setloadingSubmit(false);
          setError("Erreur de connexion, veuillez vérifier vos identifiants");
        }
      })
      .catch(function (error) {
        setloadingSubmit(false);
        console.log(error);
      });
  };

  const RenderItemLegume = () => {
    return (
      <>
        {selectedLegumes.length > 0 &&
          selectedLegumes.map((legume) => {
            return (
              <div className="relative mt-6 p-4 rounded-lg bg-gray-200 w-full py-6 flex justify-between items-center">
                <div>
                  <h4 className="text-xl mb-0">
                    {legume.variete.value} <span className="text-xs"> pour le {legume.date}</span>
                  </h4>
                  {legume.espece && <span className="text-xs">{legume.espece.value} </span>}
                  {legume.type && <span className="text-xs"> / {legume.type.value} </span>}
                  {legume.commentaire && <div className="text-xs italic">{legume.commentaire}</div>}
                </div>
                <div>
                  <p>
                    {legume.quantite} {legume.unite}
                  </p>
                </div>
                <div
                  className="absolute -top-8 -right-2 cursor-pointer inline-flex items-center p-1 border border-transparent font-medium rounded-full shadow-sm text-white bg-red-700 hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base my-6"
                  onClick={() => {
                    setDelLegume(legume);
                    setIsOpen(!isOpen);
                  }}
                >
                  <BsFillTrashFill size=".7em" />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const renderChoiceLegume = () => {
    if (choiceLegume) {
      return (
        <div className="bg-white p-6 ">
          <div className="flex justify-between">
            <Title classCustom="mb-6" title="Ajouter un légume" />
            <span className="cursor-pointer" onClick={() => setChoiceLegume(false)}>
              Fermer
            </span>
          </div>
          <AchatsLegumesList handleSelectedLegumes={handleSelectedLegumes} />
          {error &&
            <KError>{error}</KError>
          }
        </div>
      );
    } else {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          {selectedLegumes.length > 0 && <RenderItemLegume />}
          <div
            className="cursor-pointer inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base my-6"
            onClick={() => setChoiceLegume(!choiceLegume)}
          >
            <span className="font-[30px] w-4 h-4 rounded-full bg-white flex items-center justify-center text-base mr-2">
              +
            </span>
            Ajouter un produit
          </div>
          <hr />

          <div className="py-4">
            <label htmlFor="commentaire" className="block text-sm font-medium text-gray-700">
              Information complémentaire
            </label>
            <div className="mt-1">
              <textarea
                id="commentaire"
                name="commentaire"
                rows="3"
                {...register("commentaire")}
                className="max-w-lg shadow-sm block w-full focus:ring-base focus:border-base border-gray-300 rounded-md"
              ></textarea>
            </div>
          </div>

          <p className='text-xs italic'>Les tarifs du catalogue sont à titre indicatif et sont susceptibles d'être modifiés par le fournisseur.</p>
          {!loadingSubmit ? (
            <Bt title="Envoyer" type="submit" />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <Oval type="Oval" color="#48BFAD" height={100} width={100} />
            </div>
          )}
        </form>
      );
    }
  };

  useEffect(() => {
    axios
      .get(`${
        process.env.REACT_APP_APOLLO_URL ||
        'https://extranet.biobreizh.org'
      }/wp-json/bb/v1/legumes_ha`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch(ACTIONS.setLegumesHa(response.data.legumes_ha));
        }
      })
      .catch(function () {
        // ...
      });
  }, []);

  if (hasSubmited)
    return (
      <div className="m-6">
        <Title title="Commande envoyée !" />
        <p>Consulter vos emails pour vérifier votre commande</p>
      </div>
    );
  else
    return (
      <>
        {app.legumesha !== null ? (
          <>
            <div className="m-6">{renderChoiceLegume()}</div>
            <Dialog
              open={isOpen}
              onClose={setIsOpen}
              as="div"
              className={"fixed inset-0 z-[1000] flex items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-80"}
            >
              <div className="flex flex-col bg-gray-100 w-96 py-8 px-4 text-center">
                <Dialog.Overlay />

                <Dialog.Title className="text-xl font-extrabold tracking-tight">Suppression</Dialog.Title>
                <Dialog.Description className="m-6">Voulez-vous supprimer ce légume de votre commande ?</Dialog.Description>
                <div className='flex justify-center'>
                  <button className="rounded-full bg-gray-500 text-white px-4 py-2 m-1" onClick={() => setIsOpen(false)}>
                    Annuler
                  </button>
                  <button className="rounded-full bg-red-800 text-white px-4 py-2 m-1" onClick={() => {
                      setIsOpen(false)
                      let l = selectedLegumes
                      let newl = _.remove(l, function(item) {
                        return item.variete.value === delLegume.variete.value;
                      });
                    }}>
                    Confirmer
                  </button>
                </div>
              </div>
            </Dialog>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center h-screen">
            <Oval type="Oval" color="#48BFAD" height={100} width={100} />
          </div>
        )}
      </>
    );
}

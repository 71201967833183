import React, { useEffect, useState, useMemo } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axios from 'axios'
// K
import Header from '../../components/Header'
import KAlert from '../../components/KAlert'
import Subtitle from '../../components/Subtitle'
import InputText from '../../components/InputText'
import Title from '../../components/Title'
import Bt from '../../components/Bt'
import { useQuery, getNumberOfWeek, getlegumes } from '../../utils'

// Store
import { Context } from '../../store/Store'

// Icones
import annoncesSvg from '../../svg/annonces.svg'
import { BsArrowBarLeft } from 'react-icons/bs'

export function PlanificationForm() {
  let location = useLocation()
  let history = useNavigate()

  const { app, dispatch } = React.useContext(Context)
  const [legume] = React.useState(location.state.legume)
  const [infosLegume, setInfosLegumes] = React.useState(null)
  const [loadingSubmit, setloadingSubmit] = useState(false)
  const [, setError] = React.useState(null)
  const { register, handleSubmit } = useForm()

  React.useEffect(() => {
    if (location.state.mode && infosLegume === null) {
      axios
        .get(
          `${process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'}/wp-json/bb/v1/producteur_planification/${legume.planified}`
        )
        .then(function (response) {
          if (response.status === 200) {
            setInfosLegumes(response.data)
            setloadingSubmit(false)
          } else {
            setloadingSubmit(false)
          }
        })
        .catch(function (error) {
          setloadingSubmit(false)
          console.log(error)
        })
    }
  }, [])

  const onSubmit = (data) => {
    let a = {
      ...data,
      legumeId: legume.id,
      producteurId: app.profil.id,
      posttype: 'ex_planification',
    }

    axios
      .post(
        `${process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'}/wp-json/bb/v1/producteur_planification/`,
        a
      )
      .then(function (response) {
        if (response.status === 200) {
          getlegumes(app, dispatch)
          history('/planification?success=create', { replace: true })
        } else {
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className='m-6'>
      <Subtitle title='Plannification pour : ' />
      <Title title={legume.title} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name='postId'
          type='hidden'
          {...register('postId', { required: true })}
          value={infosLegume ? infosLegume.post_id : 'newpost'}
          defaultValue={infosLegume ? infosLegume.post_id : 'newpost'}
        />
        <div className='border-gray-200 py-4'>
          <InputText
            type='number'
            r={{ ...register('surface', { required: true }) }}
            defaultValue={infosLegume && infosLegume.surface}
            label='Surface - en Ha'
            name='surface'
          />
        </div>
        <div className='py-4'>
          <div className='flex items-center'>
            <input
              value='AB'
              name='certificat'
              {...register('certificat', { required: true })}
              type='radio'
              defaultChecked={infosLegume && infosLegume.certificat === 'AB'}
              className='focus:ring-base h-4 w-4 text-base border-gray-300'
            />
            <label
              for='push_everything'
              className='ml-3 mr-3 block text-sm font-medium text-gray-700'
            >
              AB
            </label>
            <input
              value='C2'
              {...register('certificat', { required: true })}
              name='certificat'
              type='radio'
              defaultChecked={infosLegume && infosLegume.certificat === 'C2'}
              className='focus:ring-base h-4 w-4 text-base border-gray-300'
            />
            <label
              for='push_email'
              className='ml-3 mr-3 block text-sm font-medium text-gray-700'
            >
              C2
            </label>
            <input
              value='Semence Paysanne'
              {...register('certificat', { required: true })}
              name='certificat'
              type='radio'
              defaultChecked={
                infosLegume && infosLegume.certificat === 'Semence Paysanne'
              }
              className='focus:ring-base h-4 w-4 text-base border-gray-300'
            />
            <label
              for='push_nothing'
              className='ml-3 mr-3 block text-sm font-medium text-gray-700'
            >
              Semence Paysanne
            </label>
          </div>
        </div>

        <div className='py-3  '>
          <label
            for='about'
            className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
          >
            Quantité
          </label>
          <div className='shadow rounded-md mt-4'>
            <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
              <InputText
                type='number'
                r={{ ...register('mai') }}
                defaultValue={infosLegume && infosLegume.mai}
                label='Mai'
                name='mai'
                classCustom='w-full'
              />
              <InputText
                r={{ ...register('juin') }}
                defaultValue={infosLegume && infosLegume.juin}
                label='Juin'
                type='number'
                name='juin'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('juillet') }}
                defaultValue={infosLegume && infosLegume.juillet}
                label='Juillet'
                name='juillet'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('aout') }}
                defaultValue={infosLegume && infosLegume.aout}
                label='Août'
                name='aout'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('septembre') }}
                defaultValue={infosLegume && infosLegume.septembre}
                label='Septembre'
                name='septembre'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('octobre') }}
                defaultValue={infosLegume && infosLegume.octobre}
                label='Octobre'
                name='octobre'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('novembre') }}
                defaultValue={infosLegume && infosLegume.novembre}
                label='Novembre'
                name='novembre'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('decembre') }}
                defaultValue={infosLegume && infosLegume.decembre}
                label='Décembre'
                name='decembre'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('janvier') }}
                defaultValue={infosLegume && infosLegume.janvier}
                label='Janvier'
                name='janvier'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('fevrier') }}
                defaultValue={infosLegume && infosLegume.fevrier}
                label='Février'
                name='fevrier'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('mars') }}
                defaultValue={infosLegume && infosLegume.mars}
                label='Mars'
                name='mars'
                classCustom='w-full'
              />
              <InputText
                type='number'
                r={{ ...register('avril') }}
                defaultValue={infosLegume && infosLegume.avril}
                label='Avril'
                name='avril'
                classCustom='w-full'
              />
            </div>
          </div>

          <div className='mt-4'>
            {!loadingSubmit ? (
              <Bt title='Envoyer' type='submit' />
            ) : (
              <div>Patienter...</div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

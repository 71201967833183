import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Store } from './store/Store'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './helpers/ScrollToTop'

// Initialize Apollo client
const client = new ApolloClient({
  uri:
    process.env.REACT_APP_APOLLO_URL ||
    'https://extranet.biobreizh.org/graphql',
  cache: new InMemoryCache(),
  request: async (operation) => {
    // Get JWT token from local storage
    const token = window.localStorage.getItem('token')

    // Pass token to headers
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Store>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </Store>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

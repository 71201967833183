import * as ACTIONS from '../../store/actions/actions'

import { BsToggleOff, BsToggleOn } from 'react-icons/bs'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import React, { useEffect } from 'react'
import Select, { components } from 'react-select'

import { Context } from '../../store/Store'
import { StepBottom } from './StepBottom'

const criteres_nc = [
  {
    label: 'Contrôle du poids ou nombre',
    value: 'Contrôle du poids ou nombre',
  },
  { label: 'Défaut Forme', value: 'Défaut Forme' },
  { label: 'Défaut Couleur', value: 'Défaut Couleur' },
  { label: 'Défauts épiderme', value: 'Défauts épiderme' },
  { label: 'Maturité', value: 'Maturité' },
  { label: 'Défauts de Calibres', value: 'Défauts de Calibres' },
  { label: 'Présentation ', value: 'Présentation ' },
  { label: 'Identification', value: 'Identification' },
  { label: 'Origine', value: 'Origine' },
  { label: 'Catégorie', value: 'Catégorie' },
  { label: 'N° lot', value: 'N° lot' },
  { label: 'Étiquette', value: 'Étiquette' },
  { label: 'Emballage', value: 'Emballage' },
  { label: 'Palette', value: 'Palette' },
]

export const Step3 = ({ nextStep, prevStep }) => {
  const { app, dispatch } = React.useContext(Context)

  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      legumes: app.blsFormState.step2.legumes?.map((item) => ({
        legume: item.legumeId.label,
        conformite: item.conformite === undefined ? true : item.conformite,
        criteres_nc: item.criteres_nc ? item.criteres_nc : '',
        comment_nc: item.comment_nc ? item.comment_nc : '',
      })),
    },
  })

  useEffect(() => {
    console.log(app.blsFormState.step2?.legumes)
  }, [])

  const onSubmit = (data) => {
    const newData = app.blsFormState.step2.legumes?.map((legume, index) => {
      if (data.legumes[index] && legume)
        return { ...legume, ...data.legumes[index] }
    })

    dispatch(
      ACTIONS.setBlsFormState({
        ...app.blsFormState,
        step2: { legumes: newData },
      })
    )
    nextStep()
  }

  const { fields } = useFieldArray({
    control,
    name: 'legumes',
  })

  const conformiteFieldNames = React.useMemo(() => {
    return fields.map((_, index) => `legumes.${index}.conformite`)
  }, [fields.length])

  const watchedConformite = useWatch({
    control,
    name: conformiteFieldNames,
  })

  return (
    <div className="max-w-7xl mx-auto p-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-4">
          <h2
            className={`flex justify-between text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 relative`}
          >
            Agréages
          </h2>
        </div>

        {fields.map((item, index) => {
          return (
            <div key={`leg-${index}`} className="py-2">
              <div
                className={`${
                  !watchedConformite[index] ? 'rounded-b' : ''
                } relative bg-green-700 p-2 rounded-t flex justify-between text-white`}
              >
                <div>{item.legume}</div>
                <Controller
                  name={`legumes.${index}.conformite`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div
                      className="flex gap-2"
                      onClick={() => {
                        console.log('change', !value)
                        onChange(!value)
                      }}
                    >
                      {!value ? (
                        <span>AVERTISSEMENT</span>
                      ) : (
                        <span>CONFORME</span>
                      )}
                      {value ? (
                        <BsToggleOn size={25} />
                      ) : (
                        <BsToggleOff size={25} />
                      )}
                    </div>
                  )}
                />
              </div>

              {!watchedConformite[index] && (
                <div className={`bg-white rounded-b p-4`}>
                  <Controller
                    name={`legumes.${index}.criteres_nc`}
                    control={control}
                    rules={{
                      required:
                        'Veuillez préciser le critère de non conformité',
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={criteres_nc}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Selectionner le critère de non conformité"
                        isClearable
                      />
                    )}
                  />

                  <div className="py-4">
                    <label
                      htmlFor="comment_nc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Commentaire
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="comment_nc"
                        {...register(`legumes.${index}.comment_nc`)}
                        rows="3"
                        className="shadow-sm block w-full focus:ring-base focus:border-base border-gray-300 rounded-md"
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}

        <StepBottom
          handlePrev={() => prevStep()}
          handleNext={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  )
}

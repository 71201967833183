import * as ACTIONS from '../../store/actions/actions'

// Icones
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useRef, useState } from 'react'
import {
  addFileToUpload,
  convertToFormData,
  getFilteredSteps,
  stepsPav,
  stepsPecColl,
  stepsPecInd,
} from './helpers.js'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { BtFormUpload } from '../../components/BtFormUpload'
// Store
import { Context } from '../../store/Store'
import { Oval } from 'react-loader-spinner'
// BT
import { PoRecapForm } from './PoRecapForm.js'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import Stepper from '../../components/Stepper'
import axios from 'axios'

var _ = require('lodash')

export function PoForm({ titleForm }) {
  const { app, dispatch } = React.useContext(Context)
  const [loadingSubmit, setloadingSubmit] = useState(false)

  const { title, mesure } = useParams()

  const [currentStep, setCurrentStep] = useState(0)
  const [steps, setSteps] = useState()

  let navigate = useNavigate()
  const location = useLocation()
  const aidetype = location.state?.aidetype

  useEffect(() => {
    if (!steps) {
      if (aidetype === 'Aides individuelles')
        setSteps(getFilteredSteps(stepsPecInd(app), mesure))
      else if (aidetype === 'Aides collectives')
        setSteps(getFilteredSteps(stepsPecColl(app), mesure))
      else if (aidetype === 'Aides à venir') setSteps(stepsPav(app), mesure)
    }
    return () => {
      dispatch(ACTIONS.setPoFormState(null))
    }
  }, [location.pathname])

  const {
    watch,
    register,
    getValues,
    trigger,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const validateStep = async (stepIndex, data) => {
    const stepErrors = {}

    for (const field of steps[stepIndex].fields) {
      const { name, validation } = field
      const dataValue = data[steps[stepIndex].groupName][name]
      if (!validation?.required) return stepErrors
      // console.log('name !== undefined', name !== undefined)
      // console.log(
      //   '(validation?.required && !dataValue)',
      //   validation?.required && !dataValue
      // )
      // console.log('dataValue === undefined', dataValue === undefined)
      // console.log('dataValue === null', dataValue === null)
      // console.log(
      //   name !== undefined &&
      //     ((validation?.required && !dataValue) ||
      //       dataValue === undefined ||
      //       dataValue === null)
      // )
      // console.log('----')
      if (
        name !== undefined &&
        ((validation?.required && !dataValue) ||
          dataValue === undefined ||
          dataValue === null)
      ) {
        stepErrors[`${steps[stepIndex].groupName}.${name}`] =
          validation?.required
        await trigger(`${steps[stepIndex].groupName}.${name}`)
      }
    }

    return stepErrors
  }

  const onSubmit = (data) => {
    setloadingSubmit(true)
    let dataFormated = convertToFormData(app.poFormState)
    // for (let pair of dataFormated.entries()) {
    //   const [key, value] = pair
    //   console.log(`Clé : ${key}`)
    //   console.log(`Valeur : ${value}`)
    // }

    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/po/`,
        dataFormated,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          setloadingSubmit(false)
          if (
            aidetype === 'Aides collectives' ||
            aidetype === 'Aides individuelles'
          )
            navigate('/po/po-home-pec?success=create', { replace: true })
          else navigate('/po/po-home-pav?success=create', { replace: true })
        } else {
          setloadingSubmit(false)
        }
      })
      .catch(function (error) {
        setloadingSubmit(false)
        console.log(error)
      })
  }

  useEffect(() => {
    //console.log('Errors updated:', errors)
  }, [errors])

  const handleNextStep = async (data, currentStep) => {
    const errorsValidation = await validateStep(currentStep, data)

    if (Object.keys(errorsValidation).length === 0) {
      const nextStep = currentStep + 1
      if (nextStep <= steps.length) {
        dispatch(ACTIONS.setPoFormState(data))
        setCurrentStep(nextStep)
      }
    }
  }

  function getFieldElement(section, field, errors) {
    const { type, name, label, subLabel, validation, placeholder, options } =
      field
    const { groupName, tooltip } = section

    switch (type) {
      case 'hidden':
        return (
          <input
            type="hidden"
            name={name}
            id={name}
            value={app.profil.id}
            {...register(name)}
          />
        )
      case 'text':
        return (
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor={name}
            >
              {label} <span className="text-red-500">*</span>
            </label>
            {errors[groupName]?.[name] && (
              <div className="text-xs text-red-700 mb-4">
                {errors[groupName]?.[name].message}
              </div>
            )}
            <input
              placeholder={placeholder}
              type="text"
              name={name}
              id={name}
              {...register(`${groupName}.${name}`, {
                ...validation,
              })}
              required={!!validation}
              className="block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
            />
          </div>
        )
      case 'date':
        return (
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor={name}
            >
              {label} <span className="text-red-500">*</span>
            </label>

            {errors[groupName]?.[name] && (
              <div className="text-xs text-red-700 mb-4">
                {errors[groupName]?.[name].message}
              </div>
            )}

            <Controller
              control={control}
              name={`${groupName}.${name}`}
              {...register(`${groupName}.${name}`, { ...validation })}
              render={({ field }) => (
                <ReactDatePicker
                  className="block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
                  placeholderText="Choisir une date"
                  locale="fr"
                  onChange={(e) => field.onChange(e)}
                  dateFormat="dd/MM/yyyy"
                  selected={field.value}
                  {...field}
                />
              )}
            />
          </div>
        )
      case 'select':
        return (
          <div className="mb-4">
            <label htmlFor={name}>{label}</label>

            {errors[groupName]?.[name] && (
              <div className="text-xs text-red-700 mb-4">
                {errors[groupName]?.[name].message}
              </div>
            )}

            <Controller
              control={control}
              name={`${groupName}.${name}`}
              id={name}
              {...register(`${groupName}.${name}`, { ...validation })}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    options={options}
                    onChange={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                    value={options.find(
                      (option) => option.value === field.value
                    )}
                    placeholder="Votre choix ..."
                  />
                )
              }}
            />
          </div>
        )
      case 'file':
        return (
          <div className="mb-4 relative z-0">
            <label>
              {label}
              {validation && <span className=" text-red-700 mb-4">*</span>}
            </label>

            {tooltip && (
              <span
                className="absolute top-8 left-4 z-10 cursor-pointer"
                onClick={() => dispatch(ACTIONS.setTooltip(tooltip))}
              >
                <BsFillQuestionCircleFill color="green" />
              </span>
            )}

            <Controller
              name={`${groupName}.${name}`}
              control={control}
              rules={{ ...register(`${groupName}.${name}`, { ...validation }) }}
              render={({ field, fieldState }) => {
                return (
                  <div>
                    <BtFormUpload {...field} />
                    {fieldState.error && (
                      <p className="text-xs text-red-700 mb-4">
                        {fieldState.error.message}
                      </p>
                    )}
                  </div>
                )
              }}
            />
          </div>
        )
      case 'add-file':
        return (
          <div
            onClick={() => {
              let newSteps = addFileToUpload(steps, field.fieldName)
              setSteps([...newSteps])
            }}
            className="cursor-pointer flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md bg-green-700 hover:bg-green-900"
          >
            + Ajouter un autre scan ou photo
          </div>
        )
      default:
        return null
    }
  }

  const renderForm = () => {
    return (
      <>
        {steps.map((section, index) => {
          const isCurrentStep = index === currentStep
          return (
            <div
              key={`section-${index}`}
              style={{ display: isCurrentStep ? 'block' : 'none' }}
            >
              <h2
                className={`text-l font-bold tracking-tight text-gray-900 mt-6 mb-4 border-b-2 pb-2`}
              >
                {section.label}
              </h2>

              {section.subLabel && (
                <p className="text-sm text-red-600 mb-2">{section.subLabel}</p>
              )}
              <input type="hidden" {...register('A__mesure')} value={mesure} />
              <input
                type="hidden"
                {...register('B__aidetype')}
                value={aidetype}
              />
              {section.fields.map((field, fieldIndex) => (
                <div key={`field-${index}-${fieldIndex}`}>
                  {getFieldElement(section, field, errors)}
                </div>
              ))}
            </div>
          )
        })}
      </>
    )
  }

  if (!steps) return <>Patienter ...</>
  else {
    return (
      <div className="bg-white p-6 mt-6 rounded-lg">
        <h2
          className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2`}
        >
          {aidetype} - Etape {currentStep + 1}
          <br />
          <span className="text-xs">
            Mesure : {mesure} {decodeURIComponent(title)}
          </span>
        </h2>

        <Stepper
          steps={steps}
          setStep={(i) => setCurrentStep(i)}
          currentStep={currentStep}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-4 border-[1px] mb-4 border-gray-300">
            {/* {errors && <KError>{JSON.stringify(errors)}</KError>} */}
            {renderForm()}
            {currentStep === steps.length - 1 && (
              <PoRecapForm steps={steps} formData={getValues()} />
            )}
          </div>
          <div className="mt-4 flex justify-center">
            <div className="my-5 flex justify-start sm:space-y-0">
              {currentStep + 1 > steps.length - 1 ? (
                <>
                  {!loadingSubmit ? (
                    <button className="inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base">
                      Finaliser
                    </button>
                  ) : (
                    <div className="text-center">
                      <Oval
                        wrapperStyle={{ justifyContent: 'center' }}
                        className="m-auto justify-center"
                        color="#0F5F3F"
                        height={40}
                        width={40}
                      />{' '}
                      Veuillez patienter... l'envoi peut être assez long
                    </div>
                  )}
                </>
              ) : (
                <span
                  onClick={() => {
                    handleNextStep(watch(), currentStep)
                  }}
                  className="cursor-pointer inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
                >
                  Continuer
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

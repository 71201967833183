import { BsToggleOff, BsToggleOn } from 'react-icons/bs'

// Icones
import { BsArrowBarLeft } from 'react-icons/bs'
// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// K
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import React from 'react'
import Subtitle from '../components/Subtitle'
import Title from '../components/Title'
import { Transition } from '@headlessui/react'

export function Docs() {
  const { app } = React.useContext(Context)
  const [isOpen, setIsOpen] = React.useState(false)

  const renderDatas = (app) => {
    if (!app.profil.second) {
      return (
        <>
          <div className='py-5 grid gap-4'>
            <ul className='divide-y divide-gray-200'>
              {app.docs &&
                app.docs.map((doc) => {
                  if (!doc) return
                  return (
                    <div className='flex rounded-md shadow mb-2'>
                      <a
                        target='_blank'
                        href={`https://extranet.biobreizh.org/g/export.php?file=${doc.id}`}
                        className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                      >
                        {doc.name}
                      </a>
                    </div>
                  )
                })}
            </ul>
          </div>

          <div className='py-5 grid gap-4'>
            <div
              className='border-b flex justify-between cursor-pointer'
              onClick={() => setIsOpen(!isOpen)}
            >
              <Title title='Archives' />
              <div>
                {isOpen ? <BsToggleOn size={25} /> : <BsToggleOff size={25} />}
              </div>
            </div>
            <Transition
              show={isOpen}
              enter-active-class='transition-opacity duration-75'
              enter-from-class='opacity-0'
              enter-to-class='opacity-100'
              leave-active-class='transition-opacity duration-150'
              leave-from-class='opacity-100'
              leave-to-class='opacity-0'
            >
              <ul className='divide-y divide-gray-200'>
                {app.docsArchive &&
                  app.docsArchive.map((doc) => {
                    if (!doc) return
                    return (
                      <div className='flex rounded-md shadow mb-2'>
                        <a
                          target='_blank'
                          href={`https://extranet.biobreizh.org/g/export.php?file=${doc.id}`}
                          className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                        >
                          {doc.name}
                        </a>
                      </div>
                    )
                  })}
              </ul>
            </Transition>
          </div>
        </>
      )
    } else {
      return <p className='text-red-700'>Vous n'avez pas accès aux documents</p>
    }
  }

  return (
    <div className='flex h-full flex-col justify-between'>
      <Header
        left={
          <Link
            to='/'
            className='flex justify-center items-center w-7 h-7 rounded-full bg-white'
          >
            <BsArrowBarLeft />
          </Link>
        }
        right=''
        title='Documents'
      />

      <div className='overflow-auto flex-1 md:pl-64 '>
        <div className='max-w-7xl mx-auto px-6 py-6'>
          <Subtitle title='Téléchargement' />
          <Title title='Documents' />
          {renderDatas(app)}
        </div>
      </div>

      <Footer />
    </div>
  )
}

import * as ACTIONS from '../store/actions/actions'

import { BsArrowBarLeft, BsArrowClockwise } from 'react-icons/bs'
import {
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useMatch,
} from 'react-router-dom'

import BtBig from '../components/BtBig'
// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
// K
import Header from '../components/Header'
import KAlert from '../components/KAlert'
import { NotationsForm } from './NotationsForm'
import React from 'react'
// Icones
import messageSvg from '../svg/messages.svg'
import { useQuery } from '../utils'

const varietes = [
  'V20',
  'V25',
  'V8',
  'V24',
  'V27',
  'V22',
  'V23',
  'V7',
  'V3',
  'V4',
  'V5',
  'M91',
  'V21',
]

export function NotationsList() {
  let query = useQuery()
  const { app, dispatch } = React.useContext(Context)
  const [success, setSuccess] = React.useState()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
    }
  })

  return (
    <div className='max-w-7xl mx-auto px-6 py-6'>
      <div className='grid xs:grid-cols-2 lg:grid-cols-3 gap-5'>
        {varietes.map((variete) => (
          <Link state={{ variete: variete }} to='notations-form'>
            <BtBig
              icon={messageSvg}
              title={variete}
              classCustom='bg-white'
              titleColor='text-black'
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

const Subtitle = ({ title, classCustom }) => {
  return (
    <p className={`text-sm tracking-tight text-gray-600 ${classCustom}`}>
      {title}
    </p>
  )
}

Subtitle.propTypes = {
  classCustom: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default Subtitle

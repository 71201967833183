export const LOGIN = 'LOGIN'
export const REFRESH = 'REFRESH'
export const SET_MESSAGES = 'SET_MESSAGES'
export const SET_BIOBREIZH = 'SET_BIOBREIZH'
export const SET_FICHES = 'SET_FICHES'
export const SET_NEWS = 'SET_NEWS'
export const SET_DOCS = 'SET_DOCS'
export const SET_PRICE = 'SET_PRICE'
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_ADHERENTS = 'SET_ADHERENTS'
export const SET_PROFIL = 'SET_PROFIL'
export const SET_LEGUMES = 'SET_LEGUMES'
export const SET_LEGUMESHA = 'SET_LEGUMESHA'
export const SET_ALL_LEGUMES = 'SET_ALL_LEGUMES'
export const SET_PLANIFICATION = 'SET_PLANIFICATION'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_ADD_BL = 'SET_ADD_BL'
export const SET_MENU_OPEN = 'SET_MENU_OPEN'
export const SET_TOKEN_NOTIFS = 'SET_TOKEN_NOTIFS'
export const SET_PO = 'SET_PO'
export const SET_TOOLTIP = 'SET_TOOLTIP'
export const SET_PO_TEXTES = 'SET_PO_TEXTES'
export const SET_POFORMSTATE = 'SET_POFORMSTATE'
export const SET_BLSFORMSTATE = 'SET_BLSFORMSTATE'
export const SET_POARCHIVES = 'SET_POARCHIVES'
export const SET_QUALITE = 'SET_QUALITE'

import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { Oval } from 'react-loader-spinner'
import { Transition } from '@headlessui/react'
import { BsToggleOff, BsToggleOn } from 'react-icons/bs'

// K
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

var _ = require('lodash')

export function PlanificationBilans() {
  const { app, dispatch } = React.useContext(Context)
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [cat, setCat] = useState(null)
  const [isOpen, setIsOpen] = useState(true)

  React.useEffect(() => {
    if (app.planification.bilans) {
      let cat = []
      app.planification.bilans.map((bilan, i) => {
        cat.push({ catname: bilan.title, show: i === 1 ? false : false })
      })
      setCat(cat)
    }
  }, [])


  React.useEffect(() => {
    if (!app.planification.bilans) {
      axios
        .get(`${process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'}/wp-json/bb/v1/planification_bilans`)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(
              ACTIONS.setPlanification({
                ...app.planification,
                bilans: response.data.planification.bilans,
              })
            )
            dispatch(ACTIONS.refresh(false))
            let cat = []
            response.data.planification.bilans.map((bilan, i) => {
              cat.push({ catname: bilan.title, show: i === 1 ? false : false })
            })
            setCat(cat)
            setLoading(false)
          } else {
            setError('Erreur de connexion, veuillez vérifier vos identifiants')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [app.planification])

  const _changeCat = (o) => {
    let newcat = []
    cat.map((c) => {
      if (c.catname === o.catname) newcat.push(o)
      else newcat.push(c)
    })
    setCat(newcat)
  }

  if (!loading) {
    return (
      <div className='w-full bg-white p-6 border-gray-200'>
        <div className='divide-y divide-gray-200 mb-4'>
          <div className='space-y-1'>
            <Subtitle title='Infos' />
            <Title title='Bilans' />

            <div className='py-5 grid gap-4'>
              {cat !== null &&
                app.planification.bilans.map((bilan) => {
                  if (!bilan) return
                  let isOpen = _.filter(cat, { catname: bilan.title })
                  return (
                    <div className='mt-4'>
                      <div
                        onClick={() =>
                          _changeCat({
                            catname: bilan.title,
                            show: !isOpen[0].show,
                          })
                        }
                        className='border-b flex justify-between'
                      >
                        <h3>{bilan.title}</h3>
                        {isOpen[0].show ? (
                          <BsToggleOn size={25} />
                        ) : (
                          <BsToggleOff size={25} />
                        )}
                      </div>

                      <Transition
                        show={isOpen[0].show}
                        enter-active-class='transition-opacity duration-75'
                        enter-from-class='opacity-0'
                        enter-to-class='opacity-100'
                        leave-active-class='transition-opacity duration-150'
                        leave-from-class='opacity-100'
                        leave-to-class='opacity-0'
                      >
                        {bilan.files.map((file) => {
                          return (
                            <div className='flex rounded-md shadow mb-2'>
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={`${process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'}/g/export.php?file=${file.id}`}
                                className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                              >
                                {file.name}
                              </a>
                            </div>
                          )
                        })}
                      </Transition>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Oval
          type="Oval"
          color="#48BFAD"
          height={100}
          width={100}
        />
      </div>
    )
  }
}

import 'react-datepicker/dist/react-datepicker.css'

import { Controller, useForm } from 'react-hook-form'
import React, { useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useLocation, useNavigate } from 'react-router-dom'

import Bt from '../components/Bt'
// Store
import { Context } from '../store/Store'
import { Oval } from 'react-loader-spinner'
import Select from 'react-select'
// K
import Subtitle from '../components/Subtitle'
import Title from '../components/Title'
import axios from 'axios'
import fr from 'date-fns/locale/fr'

// Icones

const questions = [
  'Vigueur au champ',
  'Qualité de feuillage',
  'Homogénéité des plants',
  'Couverture de pomme',
  'Qualité de pomme',
  'Blancheur de pomme',
  'Poids de la pomme',
  'Etat sanitaire',
  'Qualité générale de la variété',
]

const answer = [
  { value: 'Trop faible', label: 'Trop faible' },
  { value: 'Faible', label: 'Faible' },
  { value: 'Correct', label: 'Correct' },
  { value: 'Bon', label: 'Bon' },
  { value: 'Excellent', label: 'Excellent' },
]

registerLocale('fr', fr)

export function NotationsForm() {
  let location = useLocation()
  let history = useNavigate()

  const { app, dispatch } = React.useContext(Context)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loadingSubmit, setloadingSubmit] = useState(false)

  const [, setError] = React.useState(null)
  const { control, register, handleSubmit, getValues } = useForm()

  const convertDate = (date) => {
    return (
      date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    )
  }

  const onSubmit = (data) => {
    setloadingSubmit(true)
    let a = {
      variete: location.state.variete,
      date_debut: convertDate(startDate),
      date_fin: convertDate(endDate),
      coupe: data.coupe.value,
      question: data.question.map((q) => q.value.value),
      commentaire: data.commentaire,
      producteurId: app.profil.certificat,
    }

    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/notations/`,
        a
      )
      .then(function (response) {
        if (response.status === 200) {
          setloadingSubmit(false)
          history('/notations?success=create', { replace: true })
        } else {
          setloadingSubmit(false)
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        setloadingSubmit(false)
        console.log(error)
      })
  }

  return (
    <div className='max-w-7xl mx-auto px-6'>
      <div className='w-full py-5'>
        <Subtitle title='Notation pour : ' />
        <h2 className='text-2xl font-extrabold tracking-tight text-gray-900'>
          {location.state.variete}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='py-4'>
            <label
              for='cert1'
              className='mr-3 block text-xl font-medium text-gray-700'
            >
              Période de récolte <span className='text-red-500'>*</span>
            </label>
            <div className='text-sm text-silver-dark'>
              Veuillez sélectionner une date de début et une date de fin
            </div>
            <Controller
              control={control}
              name='date'
              render={({ field }) => (
                <ReactDatePicker
                  className='input text-xl'
                  placeholderText='Select date'
                  onChange={(date) => {
                    const [start, end] = date
                    setStartDate(start)
                    setEndDate(end)
                  }}
                  selected={startDate}
                  startDate={startDate}
                  locale='fr'
                  dateFormat='dd/MM/yyyy'
                  endDate={endDate}
                  selectsRange
                  inline
                />
              )}
            />
          </div>
          <div className='py-4'>
            <label
              for='cert1'
              className='mr-3 block text-xl font-medium text-gray-700'
            >
              Nombre de coupes <span className='text-red-500'>*</span>
            </label>
            <Controller
              control={control}
              label='Nombre de coupes'
              name='coupe'
              {...register('coupe', { required: true })}
              render={({ field }) => (
                <Select
                  {...field}
                  isSearchable={false}
                  options={[
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                    { value: 6, label: '6' },
                    { value: 7, label: '7' },
                    { value: 8, label: '8' },
                    { value: 9, label: '9' },
                    { value: 10, label: '10' },
                  ]}
                  placeholder='Sélectionnez une valeur'
                />
              )}
            />
          </div>

          {questions.map((question, index) => {
            return (
              <div className='py-4' key={`i-${index}`}>
                <label
                  for='cert1'
                  className='mr-3 block text-xl font-medium text-gray-700'
                >
                  {question} <span className='text-red-500'>*</span>
                </label>
                <Controller
                  control={control}
                  label='Evaluation'
                  {...register(`question.${index}.value`, { required: true })}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isSearchable={false}
                      options={answer}
                      placeholder='Sélectionnez une valeur'
                    />
                  )}
                />
              </div>
            )
          })}

          <div className=' pt-5'>
            <label
              htmlFor='commentaire'
              className='block text-xl font-medium text-gray-700'
            >
              Commentaire
            </label>
            <div className='mt-1'>
              <textarea
                id='commentaire'
                name='commentaire'
                rows='3'
                {...register('commentaire')}
                className='max-w-lg shadow-sm block w-full focus:ring-base focus:border-base border-gray-300 rounded-md'
              ></textarea>
            </div>
          </div>

          <div className='py-3  '>
            <div className='mt-4'>
              {!loadingSubmit ? (
                <Bt title='Envoyer' type='submit' />
              ) : (
                <div className='flex flex-col items-center justify-center'>
                  <Oval type='Oval' color='#48BFAD' height={30} width={30} />
                  Patientez ...
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

import { BsFillTrashFill } from 'react-icons/bs'

const ReviewLegumeDetails = ({ bl, handleDel }) => {
  return (
    <>
      <div className="hidden md:inline-block min-w-full py-2 align-middle my-6">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Légume
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Quantité
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Prix U.
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Conditionnement
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Conformité
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {bl.step2.legumes.map((legume, index) => (
                <tr key={legume.legumeId.label}>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">
                          {legume.legumeId.label}
                        </div>
                        <div className="text-gray-500">{legume.norme}</div>
                        <div className="text-gray-500">Ilot {legume.ilot}</div>
                        <div className="text-gray-500">{legume.qualite}</div>
                        <div className="text-gray-500">{legume.bio}</div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-900">{legume.qty}</div>
                    <div className="text-gray-500">
                      {legume.conditionnement}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="text-gray-900">{legume.price}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {legume.conditionnement && (
                      <>
                        {legume.conditionnement && (
                          <div className="text-gray-900">
                            {legume.conditionnement}
                          </div>
                        )}
                        {legume.masse && (
                          <div className="text-gray-900">
                            {legume.masse} {legume.masse_value}
                          </div>
                        )}
                      </>
                    )}
                    {legume.caisse && (
                      <div className="text-gray-900">{legume.caisse}</div>
                    )}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm sm:pr-6 font-bold">
                    {bl.step2.legumes[index].conformite ? (
                      <span className="text-green-700">Légume conforme</span>
                    ) : (
                      <span className="text-red-700">Avertissement</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="md:hidden mt-6 divide-y">
        {bl.step2.legumes.map((legume, i) => (
          <div
            key={`det-${i}`}
            className=" bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pl-6 relative"
          >
            <div>
              <p className="pb-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Légume
              </p>
              <div className="flex flex-col">
                <div className="font-medium text-gray-900">{legume.title}</div>
                <div className="text-gray-500">{legume.norme}</div>
                <div className="text-gray-500">{legume.bio}</div>
              </div>
            </div>
            <div className="flex gap-4 justify-between mt-4">
              <div>
                <p className="pb-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Quantité
                </p>
                <div className="flex flex-col">
                  <div className="text-gray-900">{legume.qty}</div>
                  <div className="text-gray-500">{legume.conditionnement}</div>
                </div>
              </div>

              <div>
                <p className="pb-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Prix U.
                </p>
                <div className="flex flex-col">
                  <div className="text-gray-900">{legume.price}</div>
                </div>
              </div>

              <div>
                <p className="pb-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Cond.
                </p>
                <div className="flex flex-col">
                  {legume.conditionnement && (
                    <>
                      {legume.conditionnement && (
                        <div className="text-gray-900">
                          {legume.conditionnement}
                        </div>
                      )}
                      {legume.masse && (
                        <div className="text-gray-900">
                          {legume.masse} {legume.masse_value}
                        </div>
                      )}
                    </>
                  )}
                  {legume.caisse && (
                    <div className="text-gray-900">{legume.caisse}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ReviewLegumeDetails

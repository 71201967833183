import React, { useEffect } from 'react'
import axios from 'axios'

import { Oval } from 'react-loader-spinner'

// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

// K
import { useBiobreizhDatas } from '../../hooks/useBiobreizhDatas'
import Subtitle from '../../components/Subtitle'
import InputSearch from '../../components/InputSearch'
import Title from '../../components/Title'
var _ = require('lodash')

export function BiobreizhCertificats() {
  const { app, dispatch } = React.useContext(Context)
  const [ loading ] = useBiobreizhDatas()
  const [certificats, setCertificats] = React.useState(null)

  React.useEffect(() => {
    console.log('certificats', certificats)
    if (loading) {
      setCertificats(app.biobreizh.certificats)
    }
  }, [loading, certificats])

  return (
    <div className='mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8'>
      <div className='space-y-4'>
        {!loading ? (
          <div className='w-full bg-white p-6 border-gray-200 rounded-lg'>
            <div className='divide-y divide-gray-200 mb-4'>
              <div className='space-y-1'>
                <Subtitle title='Biobreizh' />
                <Title title='Certificats' />

                <InputSearch filterableList={app.biobreizh.certificats} handleFilter={setCertificats} searchField="name" placeHolder="Rechercher un certificat" />

                <div className='py-5 grid gap-4'>
                  {certificats &&
                    certificats.map((ref) => {
                      if (!ref) return
                      return (
                        <div className='flex rounded-md shadow mb-2'>
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href={`${
                              process.env.REACT_APP_APOLLO_URL ||
                              'https://extranet.biobreizh.org'
                            }/g/export.php?file=${ref.id}`}
                            className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                          >
                            {ref.name}
                          </a>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center h-screen'>
            <Oval type='Oval' color='#48BFAD' height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  )
}

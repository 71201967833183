export const StepBottom = ({ handleNext, handlePrev, handleSubmit }) => {
  return (
    <div className="py-4">
      <div className="h-1 bg-black w-full my-4"></div>
      <div className="flex justify-between">
        {handlePrev && (
          <button
            onClick={handlePrev}
            className="inline-flex items-center px-6 border border-transparent font-medium rounded-full shadow-sm text-white bg-mesdocs hover:bg-mesdocs focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mesdocs"
          >
            Précédent
          </button>
        )}

        {handleNext && (
          <button
            onClick={handleNext}
            className="inline-flex items-center px-6 py-2 border border-transparent font-medium rounded-full shadow-sm text-white bg-mesdocs hover:bg-mesdocs focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mesdocs"
          >
            Suivant
          </button>
        )}

        {handleSubmit && (
          <button
            onClick={handleSubmit}
            className="inline-flex items-center px-6 py-2 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mesdocs"
          >
            Valider et envoyer le bl
          </button>
        )}
      </div>
    </div>
  )
}

import * as ACTION_TYPES from '../actions/action_types'

export const initialStateApp = {
  logued: null,
  tokenNotifs: false,
  menuOpen: false,
  infosScreen: {
    title: 'Accueil',
  },
  refresh: true,
  messages: [],
  news: [],
  legumes: [],
  adherents: [],
  legumesha: null,
  alllegumes: [],
  qualite: [],
  docs: [],
  docsArchive: [],
  planification: [],
  biobreizh: [],
  price: '',
  profil: '',
  po: '',
  tooltip: null,
  poTextes: '',
  poArchives: [],
  poFormState: null,
  blsFormState: null,
  bl: '',
  clients: '',
  notifications: [], // { "subject": "messages | actus", "title": "", "body: ""}
}

export const handleActions = {
  [ACTION_TYPES.SET_MENU_OPEN]: (store, action) => {
    return {
      ...store,
      menuOpen: action.payload,
    }
  },
  [ACTION_TYPES.LOGIN]: (store, action) => {
    return {
      ...store,
      logued: action.payload,
    }
  },
  [ACTION_TYPES.REFRESH]: (store, action) => {
    return {
      ...store,
      refresh: action.payload,
    }
  },
  [ACTION_TYPES.SET_NOTIFICATIONS]: (store, action) => {
    return {
      ...store,
      notifications: action.payload,
    }
  },
  [ACTION_TYPES.SET_MESSAGES]: (store, action) => {
    return {
      ...store,
      messages: action.payload,
    }
  },
  [ACTION_TYPES.SET_NEWS]: (store, action) => {
    return {
      ...store,
      news: action.payload,
    }
  },
  [ACTION_TYPES.SET_DOCS]: (store, action) => {
    return {
      ...store,
      docs: action.payload.docs,
      docsArchive: action.payload.docs_archives,
    }
  },
  [ACTION_TYPES.SET_PLANIFICATION]: (store, action) => {
    return {
      ...store,
      planification: action.payload,
    }
  },
  [ACTION_TYPES.SET_BIOBREIZH]: (store, action) => {
    return {
      ...store,
      biobreizh: action.payload,
    }
  },
  [ACTION_TYPES.SET_ADHERENTS]: (store, action) => {
    return {
      ...store,
      adherents: action.payload,
    }
  },
  [ACTION_TYPES.SET_FICHES]: (store, action) => {
    return {
      ...store,
      fiches: action.payload,
    }
  },
  [ACTION_TYPES.SET_PRICE]: (store, action) => {
    return {
      ...store,
      price: action.payload,
    }
  },
  [ACTION_TYPES.SET_CLIENTS]: (store, action) => {
    return {
      ...store,
      clients: action.payload,
    }
  },
  [ACTION_TYPES.SET_PROFIL]: (store, action) => {
    return {
      ...store,
      profil: action.payload,
    }
  },
  [ACTION_TYPES.SET_LEGUMES]: (store, action) => {
    return {
      ...store,
      legumes: action.payload,
    }
  },
  [ACTION_TYPES.SET_LEGUMESHA]: (store, action) => {
    return {
      ...store,
      legumesha: action.payload,
    }
  },
  [ACTION_TYPES.SET_ALL_LEGUMES]: (store, action) => {
    return {
      ...store,
      alllegumes: action.payload,
    }
  },
  [ACTION_TYPES.SET_PLANIFICATION]: (store, action) => {
    return {
      ...store,
      planification: action.payload,
    }
  },
  [ACTION_TYPES.SET_ADD_BL]: (store, action) => {
    return {
      ...store,
      bl: action.payload,
    }
  },
  [ACTION_TYPES.SET_TOKEN_NOTIFS]: (store, action) => {
    return {
      ...store,
      tokenNotifs: action.payload,
    }
  },
  [ACTION_TYPES.SET_PO]: (store, action) => {
    return {
      ...store,
      po: action.payload,
    }
  },
  [ACTION_TYPES.SET_PO_TEXTES]: (store, action) => {
    return {
      ...store,
      poTextes: action.payload,
    }
  },
  [ACTION_TYPES.SET_TOOLTIP]: (store, action) => {
    return {
      ...store,
      tooltip: action.payload,
    }
  },
  [ACTION_TYPES.SET_POFORMSTATE]: (store, action) => {
    return {
      ...store,
      poFormState: action.payload,
    }
  },
  [ACTION_TYPES.SET_BLSFORMSTATE]: (store, action) => {
    return {
      ...store,
      blsFormState: action.payload,
    }
  },
  [ACTION_TYPES.SET_POARCHIVES]: (store, action) => {
    return {
      ...store,
      poArchives: action.payload,
    }
  },
  [ACTION_TYPES.SET_QUALITE]: (store, action) => {
    return {
      ...store,
      qualite: action.payload,
    }
  },
}

export const appReducer = (store, action) =>
  Boolean(handleActions[action.type])
    ? handleActions[action.type](store, action)
    : store

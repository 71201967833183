import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import * as ACTIONS from '../store/actions/actions'

import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from 'react-icons/bs'
import { Document, Page, pdfjs } from 'react-pdf'
import React, { forwardRef, useEffect, useRef } from 'react'

import { BsFillTrashFill } from 'react-icons/bs'
import BtBig from './BtBig'
// Store
import { Context } from '../store/Store'
import upSvg from '../svg/ic-boite.svg'
import { useState } from 'react'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
}

export const BtFormUpload = forwardRef(({ onChange, name, value }, ref) => {
  const { app, dispatch } = React.useContext(Context)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const pdfWrapperRef = React.useRef()
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getElementSize = () => {
      if (pdfWrapperRef.current) {
        const { width, height } = pdfWrapperRef.current.getBoundingClientRect()
        setElementSize({ width, height })
      }
    }

    getElementSize()

    const handleResize = () => {
      getElementSize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    const { width, height } = pdfWrapperRef.current.getBoundingClientRect()
    setElementSize({ width, height })

    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const [uploadedFile, setUploadedFile] = useState()
  const inputRef = useRef()

  useEffect(() => {
    if (app.poFormState && app.poFormState.hasOwnProperty(name)) {
      if (!app.poFormState[name]) return
      let newfile = app.poFormState[name]
      setUploadedFile({ newfile, field: newfile.name })
    }
  }, [app.poFormState, name])

  const handleClick = (event) => {
    inputRef.current.click()
  }

  const handleFiles = (event) => {
    const newfile = event.target.files[0]
    setUploadedFile({ newfile, field: event.target.name })
    onChange(newfile)
  }

  const handleFileRemove = () => {
    let newStore = { ...app.poFormState, [name]: null }
    dispatch(ACTIONS.setPoFormState(newStore))
    setUploadedFile(null)
    onChange(null)
  }

  const previewImage = (file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const imageSrc = URL.createObjectURL(file)
      return <img src={imageSrc} className="max-w-full" />
    } else if (file.type === 'application/pdf') {
      return (
        <div ref={pdfWrapperRef}>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            <Page
              pageNumber={pageNumber}
              width={elementSize.width || undefined}
            />
          </Document>
          <div className="flex justify-between p-4 ">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <BsFillArrowLeftCircleFill size={30} />
            </button>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} sur {numPages || '--'}
            </p>
            <button
              type="button"
              disabled={numPages ? pageNumber >= numPages : true}
              onClick={nextPage}
            >
              <BsFillArrowRightCircleFill size={30} />
            </button>
          </div>
        </div>
      )
    } else {
      console.log('Type de fichier non pris en charge')
    }
  }
  return (
    <>
      <div
        className="md:grid md:grid-cols-2
          grid-flow-row gap-4 auto-rows-auto"
      >
        <div className="relative">
          <span className="relative" onClick={handleClick}>
            <BtBig
              icon={upSvg}
              title="Charger un fichier"
              classCustom="hover:bg-gray-200 cursor-pointer border border-1 bg-white px-4 min- h-[180px] relative"
              titleColor="text-black"
            />
          </span>
          <input
            ref={inputRef}
            onChange={handleFiles}
            name={name}
            type="file"
            style={{ visibility: 'hidden' }}
          />
        </div>
        <div>
          {uploadedFile && (
            <div className="bg-gray-200 shadow-sm relative">
              <div className="font-xs italic text-center bg-white p-2">
                {uploadedFile.newfile.name} {uploadedFile.field}
              </div>
              <div className="p-4">{previewImage(uploadedFile.newfile)}</div>
              <div
                className="absolute -top-3 right-3 cursor-pointer inline-flex items-center p-1 border border-transparent font-medium rounded-full shadow-sm text-white bg-red-700 hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base my-6"
                onClick={() => handleFileRemove()}
              >
                <BsFillTrashFill size=".7em" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
})

const _ = require('lodash')

export const stepsPav = (app) => {
  return [
    {
      label: 'Vos devis',
      stepperLabel: 'Devis',
      groupName: 'devis',
      fields: [
        {
          type: 'file',
          name: 'devis-fichier',
          label: 'Ajouter le devis N°1',
          validation: {
            required: 'Veuillez ajouter le scan ou la photo de vos devis',
          },
        },
        {
          type: 'file',
          name: 'devis-fichier2',
          label: 'Ajouter le devis N°2',
          validation: {
            required: 'Veuillez ajouter le scan ou la photo de vos devis',
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un autre devis',
          fieldName: 'devis-fichier2',
        },
      ],
    },
    {
      label: 'Validation',
      stepperLabel: 'Validation',
      groupName: 'validation',
      fields: [
        {
          type: 'hidden',
          name: 'producteur',
        },
      ],
    },
  ]
}

export const stepsPecColl = (app) => {
  return [
    {
      label: 'Vos factures acquittées',
      stepperLabel: 'Factures',
      groupName: 'F__factures',
      fields: [
        {
          type: 'date',
          placeholder: '',
          name: 'facture-date',
          label: 'Date de facture',
          validation: { required: 'Veuillez saisir la date de la facture' },
        },
        {
          type: 'text',
          placeholder: '',
          name: 'facture-numero',
          label: 'Numéro de facture',
          validation: { required: 'Veuillez saisir le numéro de la facture' },
        },
        {
          type: 'file',
          name: 'facture-fichier',
          label: 'Scan ou photo de la facture',
          tooltip: {
            id: 'E__factures.facture-fichier',
            title: 'Modèle de facture',
            desc: '',
            src: 'facture.jpg',
          },
          validation: {
            required: 'Veuillez ajouter le scan ou la photo de la facture',
          },
        },
        {
          type: 'file',
          name: 'facture-fichier2',
          disabledFor: [
            '1.26',
            '1.29',
            '3.7.3',
            '3.4.3',
            '3.4.4',
            '3.7.2',
            '3.4.6.3',
            '2.20',
            '3.11.5',
            '2.21',
            '3.4.6',
            '2.29',
          ],
          label: 'Second scan ou photo de la facture',
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'facture-fichier',
        },
      ],
    },
    {
      label: 'Relevés bancaires ou preuves de paiements',
      stepperLabel: 'Paiements',
      groupName: 'G__paiements',
      fields: [
        {
          type: 'file',
          name: 'releve-fichier',
          tooltip: {
            id: 'F__releves.releve-fichier',
            title: 'Modèle de relevé',
            desc: '',
            src: 'releve.jpg',
          },
          label: 'Scan(s) ou photo(s)',
          validation: {
            required: 'Veuillez ajouter une ou plusieurs preuves de paiements',
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'releve-fichier',
        },
      ],
    },
    {
      label: 'Déclaration',
      stepperLabel: 'Déclaration',
      groupName: 'E__divers',
      fields: [
        {
          type: 'text',
          placeholder: '',
          name: 'surface',
          label: 'surface (en Ha)',
          disabledFor: ['2.20', '3.11.5', '2.21', '2.27', '3.8.2'],
          validation: {
            required: 'Veuillez saisir les informations de surface',
          },
        },
        {
          type: 'select',
          options: app.fiches.map((fiche) => {
            return {
              value: fiche.name,
              label: fiche.name,
              title: fiche.name,
            }
          }),
          name: 'culture',
          label: 'Culture',
          disabledFor: ['2.20', '3.11.5', '2.21', '2.27', '3.8.2'],
          validation: { required: 'Veuillez sélectionner une choix' },
        },
        {
          type: 'text',
          placeholder: '',
          name: 'fournisseur',
          label: 'Fournisseur',
          validation: { required: 'Veuillez aisir un fournisseur' },
        },
        {
          type: 'select',
          options: [
            {
              value: '1 FOIS',
              label: '1 FOIS',
              title: '1 FOIS',
            },
            {
              value: '2 FOIS',
              label: '2 FOIS',
              title: '2 FOIS',
            },
            {
              value: '3 FOIS',
              label: '3 FOIS',
              title: '3 FOIS',
            },
            {
              value: '4 FOIS',
              label: '4 FOIS',
              title: '4 FOIS',
            },
            {
              value: '4 FOIS ET +',
              label: '4 FOIS ET +',
              title: '4 FOIS ET +',
            },
          ],
          name: 'paiement',
          label: 'Paiement en x fois',
          validation: { required: 'Veuillez sélectionner une choix' },
        },
        {
          type: 'select',
          options: [
            {
              value: 'CHEQUE',
              label: 'CHEQUE',
              title: 'CHEQUE',
            },
            {
              value: 'PRÉLÈVEMENT',
              label: 'PRÉLÈVEMENT',
              title: 'PRÉLÈVEMENT',
            },
            {
              value: 'TRAITE',
              label: 'TRAITE',
              title: 'TRAITE',
            },
            {
              value: 'VIREMENT',
              label: 'VIREMENT',
              title: 'VIREMENT',
            },
          ],
          name: 'mode-paiement',
          label: 'Mode de paiement',
          validation: { required: 'Veuillez sélectionner un mode de paiement' },
        },
      ],
    },
    {
      label: 'Rapport d’audit',
      stepperLabel: 'Audits',
      disabledFor: [
        '2.20',
        '2.27',
        '2.29',
        '3.4.6',
        '3.4.4',
        '3.8.2',
        '3.4.6.3',
        '3.6.1',
      ],
      groupName: 'H__audits',
      fields: [
        {
          type: 'file',
          name: 'audit-fichier',
          label: 'Scan(s) ou photo(s)',
          validation: {
            required: "Veuillez ajouter une ou plusieurs rapport d'audit",
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'audit-fichier',
        },
      ],
    },
    {
      label: 'Rapport d’analyse',
      stepperLabel: 'Analyses',
      disabledFor: [
        '2.20',
        '3.11.5',
        '2.21',
        '3.4.6',
        '2.29',
        '3.4.4',
        '3.8.2',
        '3.4.6.3',
        '3.6.1',
      ],
      groupName: 'I__analyses',
      fields: [
        {
          type: 'file',
          name: 'analyse-fichier',
          label: 'Scan(s) ou photo(s)',
          validation: {
            required: "Veuillez ajouter une ou plusieurs rapport d'analyse",
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'analyse-fichier',
        },
      ],
    },
    {
      label: 'Validation',
      stepperLabel: 'Validation',
      groupName: 'validation',
      fields: [
        {
          type: 'hidden',
          name: 'producteur',
        },
      ],
    },
  ]
}

export const stepsPecInd = (app) => {
  return [
    {
      label: 'Vos factures',
      stepperLabel: 'Factures',
      groupName: 'E__factures', // le X__ Permet d'ordonner les champs dans le recap
      tooltip: {
        id: 'E__factures.facture-fichier',
        title: 'Modèle de facture',
        desc: '',
        src: 'facture.jpg',
      },
      fields: [
        {
          type: 'date',
          placeholder: '',
          name: 'facture-date',
          label: 'Date de facture',
          validation: { required: 'Veuillez saisir la date de la facture' },
        },
        {
          type: 'text',
          placeholder: '',
          name: 'facture-numero',
          label: 'Numéro de facture',
          validation: {
            required: 'Veuillez saisir le numéro de la facture',
          },
        },
        {
          type: 'file',
          name: 'facture-fichier',
          tooltip: {
            id: 'E__factures.facture-fichier',
            title: 'Modèle de facture',
            desc: '',
            src: 'facture.jpg',
          },
          label: 'Scan(s) ou photo(s) de la facture',
          validation: {
            required: 'Veuillez ajouter le scan ou la photo de la facture',
          },
        },
        {
          type: 'file',
          name: 'facture-fichier2',
          disabledFor: [
            '0.0.0',
            '1.26',
            '1.29',
            '1.33',
            '3.7.3',
            '3.4.3',
            '3.4.4',
            '3.7.2',
            '2.20',
            '3.11.5',
            '2.21',
            '3.4.6',
            '2.29',
            '2.16',
            '3.5.8',
          ],
          label: 'Second scan ou photo de la facture',
          validation: {
            required: 'Veuillez ajouter le scan ou la photo de la facture',
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'facture-fichier',
        },
      ],
    },
    {
      label: 'Relevés bancaires',
      subLabel:
        'L’identité du détenteur du compte doit obligatoirement apparaitre sur le relevé bancaire.',
      stepperLabel: 'Paiements',
      groupName: 'F__releves', // le X__ Permet d'ordonner les champs dans le recap
      tooltip: {
        id: 'F__releves.releve-fichier',
        title: 'Modèle de relevé',
        desc: '',
        src: 'releve.jpg',
      },
      fields: [
        {
          type: 'select',
          options: [
            {
              value: '1 FOIS',
              label: '1 FOIS',
              title: '1 FOIS',
            },
            {
              value: '2 FOIS',
              label: '2 FOIS',
              title: '2 FOIS',
            },
            {
              value: '3 FOIS',
              label: '3 FOIS',
              title: '3 FOIS',
            },
            {
              value: '4 FOIS',
              label: '4 FOIS',
              title: '4 FOIS',
            },
            {
              value: '4 FOIS ET +',
              label: '4 FOIS ET +',
              title: '4 FOIS ET +',
            },
          ],
          name: 'paiement',
          label: 'Paiement en x fois',
          validation: { required: 'Veuillez sélectionner une choix' },
        },
        {
          type: 'file',
          name: 'releve-fichier',
          label: 'Ajouter un relevé',
          validation: {
            required: 'Veuillez ajouter un scan ou photo de vos releves',
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'releve-fichier',
        },
      ],
    },
    {
      label: 'Photos',
      stepperLabel: 'Photos',
      groupName: 'G__photos', // le X__ Permet d'ordonner les champs dans le recap
      fields: [
        {
          type: 'file',
          name: 'photo-fichier',
          label: 'Scan(s) ou photo(s)',
          validation: {
            required: 'Veuillez ajouter une ou plusieurs photos',
          },
        },
        {
          type: 'add-file',
          label: 'Ajouter un fichier',
          fieldName: 'photo-fichier',
        },
      ],
    },
    {
      label: 'Validation',
      stepperLabel: 'Validation',
      fields: [
        {
          type: 'hidden',
          name: 'producteur',
        },
      ],
    },
  ]
}

export const getFilteredSteps = (steps, mesure) => {
  const removeDisabledItems = (data, mesure, depth = 0) => {
    const mesureNumber = mesure //sparseFloat(mesure);

    return data.filter((item) => {
      if (
        item.disabledFor &&
        //!isNaN(mesureNumber) && // Quand les mesures sont en number
        item.disabledFor.includes(mesureNumber)
      ) {
        return false
      }

      if (item.fields) {
        item.fields = removeDisabledItems(item.fields, mesureNumber, depth + 1)
        return item.fields.length > 0
      }

      item.depth = depth
      return true
    })
  }

  return removeDisabledItems(steps, mesure)
}

export const previewImage = (file) => {
  const imageSrc = URL.createObjectURL(file)
  return <img src={imageSrc} className="max-w-full" />
}

export const addFileToUpload = (steps, fieldName) => {
  let newSteps = steps
  // Trouver l'index de l'objet
  const parent = _.find(steps, { fields: [{ name: fieldName }] })
  const parentIndex = _.findIndex(steps, { fields: [{ name: fieldName }] })

  // Vérifier si l'objet parent a un tableau de champs (fields)
  let fieldsArray = parent.fields
  let fieldId = Date.now()

  // Insérer le nouvel objet dans le tableau de champs (fields)
  let insertIndex = fieldsArray.length - 1

  fieldsArray.splice(insertIndex, 0, {
    type: 'file',
    name: `${fieldName}-${fieldId}`,
    label: 'Scan(s) ou photo(s)',
  })

  // On ajoute la date si néçessaire
  if (fieldName === 'facture') {
    fieldsArray.splice(insertIndex, 0, {
      type: 'date',
      name: `facture-date-${fieldName}-${fieldId}`,
      label: 'Date',
    })
    // fieldsArray.splice(insertIndex, 0, {
    //   type: 'text',
    //   name: `facturenumero-${fieldName}-${fieldId}`,
    //   label: 'Numéro de la facture',
    // })
  }

  // Mettre à jour l'objet initial avec le nouveau tableau de champs modifié
  newSteps[parentIndex].fields = fieldsArray
  return newSteps
}

export function convertToFormData(data) {
  const formData = new FormData()

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key]

      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item instanceof File) {
            formData.append(key.replace(/^.*?__/, ''), item)
          } else if (typeof item === 'object') {
            formData.append(key.replace(/^.*?__/, ''), JSON.stringify(item))
          } else {
            formData.append(key.replace(/^.*?__/, ''), String(item))
          }
        })
      } else if (value instanceof File) {
        formData.append(key.replace(/^.*?__/, ''), value)
      } else if (typeof value === 'object') {
        for (const key in value) {
          let k = key.replace(/^.*?__/, '')
          if (value[key] instanceof File) {
            formData.append(`${k}`, value[key])
          } else if (typeof item === 'object') {
            formData.append(`${k}`, JSON.stringify(value[key]))
          } else {
            formData.append(`${k}`, String(value[key]))
          }
        }
        //formData.append(key.replace(/^.*?__/, ''), JSON.stringify(value))
      } else {
        formData.append(key.replace(/^.*?__/, ''), String(value))
      }
    }
  }

  return formData
}

import * as ACTIONS from '../../store/actions/actions'

import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import React, { useEffect, useRef, useState } from 'react'
import Select, { components } from 'react-select'

import { BsFillTrashFill } from 'react-icons/bs'
import { Context } from '../../store/Store'
import { StepBottom } from './StepBottom'

const customOption = (props) => {
  console.log(props)
  return (
    <components.Option {...props}>
      <div className="flex gap-4">
        <img
          src={props.data.th}
          style={{ width: 24, height: 24, marginRight: 10, objectFit: 'cover' }}
          alt=""
        />
        {props.label}
      </div>
    </components.Option>
  )
}

export const Step2 = ({ nextStep, prevStep }) => {
  const { app, dispatch } = React.useContext(Context)
  const selectRef = useRef()
  const [currentField, setCurrentField] = useState(null)
  const [errorsLegumes, setErrorsLegumes] = useState(null)
  const [showForm, setShowForm] = useState(
    app.blsFormState.step2
      ? app.blsFormState.step2.legumes.map((leg) => false)
      : []
  )
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: app.blsFormState.step2?.legumes
      ? { legumes: app.blsFormState.step2.legumes }
      : {},
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'legumes',
  })

  const onSubmit = (data) => {
    const cleanedData = Object.entries(data.legumes).reduce(
      (acc, [key, value]) => {
        console.log(key)
        if (value !== undefined) {
          acc[key] = value
        }
        return acc
      },
      []
    )

    const legumesWithNorme = () => {
      return cleanedData.map((d) => {
        return {
          ...d,
          norme: app.legumes.find((p) => p.id === d.legumeId.value)?.norme,
        }
      })
    }

    dispatch(
      ACTIONS.setBlsFormState({
        ...app.blsFormState,
        step2: { legumes: legumesWithNorme() },
      })
    )
    nextStep()
  }

  const onError = (errors, e) => {
    alert(
      'Des erreurs ont été détectées, veuillez remplir tous les champs obligatoires'
    )
    setErrorsLegumes(errors)
    setShowForm((prev) => {
      return prev.map((_, i) => {
        return errors.legumes.hasOwnProperty(i) ? true : false
      })
    })
  }

  const watcheduniteValues = useWatch({
    control,
    name: fields.map((_, index) => `legumes.${index}.unite`),
    defaultValue: fields.map((field, index) => {
      return app.blsFormStatee?.step2?.legumes
        ? app.blsFormState?.step2.legumes[index]?.unite || false
        : false
    }),
  })

  return (
    <div className="max-w-7xl mx-auto p-6">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="pt-4">
          <h2
            className={`flex justify-between text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 relative`}
          >
            Légumes
          </h2>
        </div>

        <div>
          {fields.map((item, index) => {
            return (
              <div key={item.id} className="py-2">
                {/* LIST */}
                <div
                  className={`${
                    currentField !== index ? 'rounded-b' : ''
                  } relative bg-base p-2 rounded-t flex justify-between text-white`}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setShowForm((prev) => {
                        const updated = [...prev]
                        updated[index] = !updated[index]

                        selectRef.current.focus()

                        return updated
                      })
                    }}
                  >
                    {item.legumeId?.label || 'Veuillez préciser le légume'}
                  </div>
                  <div
                    className="absolute right-4 cursor-pointer inline-flex items-center p-1 border border-transparent font-medium rounded-full shadow-sm text-white bg-white hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
                    onClick={() => remove(index)}
                  >
                    <BsFillTrashFill size=".7em" color="red" />
                  </div>
                </div>

                {/* FORM */}
                <div
                  className={`bg-white rounded-b p-4 ${
                    showForm[index] ? '' : 'hidden'
                  }`}
                >
                  <Controller
                    control={control}
                    name={`legumes.${index}.legumeId`}
                    rules={{
                      required: 'Veuillez préciser le légume',
                    }}
                    render={({ field }) => (
                      <>
                        <label
                          htmlFor="react-select-33-input"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Légume
                          <span className="text-red-500">*</span>
                        </label>
                        <Select
                          {...field}
                          options={app.legumes.map((legume) => {
                            return {
                              value: legume.id,
                              label: legume.title,
                              th: legume.thumb,
                            }
                          })}
                          inputRef={selectRef}
                          id="selectLegume"
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Sélectionnez un légume"
                          components={{
                            Option: customOption,
                          }}
                          isClearable
                        />
                      </>
                    )}
                  />
                  <div className="py-4">
                    <div className="border-gray-200 py-4">
                      <div className="w-full">
                        <label
                          htmlFor="ilot"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Numéro d’îlot
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="number"
                          id="ilot"
                          {...register(`legumes.${index}.ilot`, {
                            required: true,
                          })}
                          className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
                        />
                      </div>
                      {/* <InputText
                        label="Numéro d’îlot"
                        id="ilot"
                        type="text"
                        classCustom="w-full"
                        r={{
                          ...register(`legumes.${index}.ilot`, {
                            required: true,
                          }),
                        }}
                      /> */}
                    </div>
                    <div className="py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Mentions obligatoires
                      </h2>
                      <div className="flex items-center">
                        <input
                          id="mode1"
                          type="radio"
                          value="Biobreizh"
                          {...register(`legumes.${index}.bio`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="mode1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Biobreizh
                        </label>
                        <input
                          id="mode2"
                          type="radio"
                          value="AB"
                          {...register(`legumes.${index}.bio`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="mode2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          AB
                        </label>
                        <input
                          id="mode3"
                          type="radio"
                          value="C2"
                          {...register(`legumes.${index}.bio`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="mode3"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          C2 <span className="text-red-500">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Qualités et labels complémentaires
                      </h2>
                      <div className="flex items-center">
                        <input
                          id="label1"
                          type="radio"
                          value="Aucun"
                          {...register(`legumes.${index}.qualite`, {
                            required: false,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="label1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Aucun
                        </label>
                        <input
                          id="label2"
                          type="radio"
                          value="Semence Paysanne"
                          {...register(`legumes.${index}.qualite`, {
                            required: false,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="label2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Semence Paysanne
                        </label>
                        <input
                          id="label3"
                          type="radio"
                          value="AOP"
                          {...register(`legumes.${index}.qualite`, {
                            required: false,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="label3"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          AOP
                        </label>
                      </div>
                    </div>

                    <div className="border-gray-200 py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Quantités
                      </h2>
                      <div className="w-full">
                        <label
                          htmlFor="qty"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Quantité
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="number"
                          id="qty"
                          {...register(`legumes.${index}.qty`, {
                            required: true,
                          })}
                          className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
                        />
                      </div>
                      {/* <InputText
                        label="Quantité"
                        id="qty"
                        type="number"
                        classCustom="w-full"
                        r={{
                          ...register(`legumes.${index}.qty`, {
                            required: true,
                          }),
                        }}
                      /> */}
                    </div>
                    <div className="py-4">
                      <div className="flex items-center">
                        <input
                          id="unite1"
                          type="radio"
                          value="Kg"
                          {...register(`legumes.${index}.unite`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="unite1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Kg
                        </label>
                        <input
                          id="unite2"
                          type="radio"
                          value="Colis"
                          {...register(`legumes.${index}.unite`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="unite2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Colis
                        </label>
                        <input
                          id="unite3"
                          type="radio"
                          value="Pièce"
                          {...register(`legumes.${index}.unite`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="unite3"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Pièce
                        </label>
                        <input
                          id="unite4"
                          type="radio"
                          value="Filet"
                          {...register(`legumes.${index}.unite`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="unite4"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Filet <span className="text-red-500">*</span>
                        </label>
                      </div>
                    </div>
                    {['Filet', 'Colis'].includes(watcheduniteValues[index]) && (
                      <div className="border-gray-200 pt-5">
                        <div className="w-full">
                          <label
                            htmlFor="masse"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Masse ou nombre par colis{' '}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            step="0.01"
                            type="number"
                            id="masse"
                            {...register(`legumes.${index}.masse`, {
                              required: true,
                            })}
                            className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
                          />
                        </div>
                        {/* <InputText
                          label=" Masse ou nombre par colis"
                          id="masse"
                          type="number"
                          classCustom="w-full"
                          r={{
                            ...register(`legumes.${index}.masse`, {
                              required: true,
                            }),
                          }}
                        /> */}
                        <input
                          id="masse_value1"
                          type="radio"
                          value="Kg"
                          {...register(`legumes.${index}.masse_value`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 text-base border-gray-300"
                        />{' '}
                        <label
                          htmlFor="masse_value1"
                          className="mr-3 text-sm font-medium text-gray-700"
                        >
                          Kg
                        </label>
                        <input
                          id="masse_value2"
                          type="radio"
                          value="Pièce"
                          {...register(`legumes.${index}.masse_value`, {
                            required: true,
                          })}
                          className="focus:ring-base h-4 text-base border-gray-300"
                        />{' '}
                        <label
                          htmlFor="masse_value2"
                          className="mr-3 text-sm font-medium text-gray-700"
                        >
                          Pièce
                        </label>
                      </div>
                    )}

                    <div className="border-gray-200 py-4 ">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Prix unitaire HT
                      </h2>

                      <div className="w-full">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prix unitaire <span className="text-red-500">*</span>
                        </label>
                        <input
                          step="0.01"
                          type="number"
                          id="price"
                          {...register(`legumes.${index}.price`, {
                            required: true,
                          })}
                          className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
                        />
                      </div>

                      {/* <InputText
                        label="Prix unitaire "
                        id="price"
                        type="number"
                        classCustom="w-full"
                        r={{
                          ...register(`legumes.${index}.price`, {
                            required: true,
                          }),
                        }}
                      /> */}
                    </div>

                    <div className="py-4">
                      <h2
                        className={`text-l font-bold tracking-tight text-gray-900 mt-2 mb-4 border-b-2 pb-2 max-w-lg`}
                      >
                        Conditionnement
                      </h2>
                      <div className="flex items-center">
                        <input
                          id="caisse1"
                          type="radio"
                          {...register(`legumes.${index}.caisse`)}
                          value="Logé"
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="caisse1"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Logé
                        </label>
                        <input
                          id="caisse2"
                          type="radio"
                          {...register(`legumes.${index}.caisse`)}
                          value="Nu"
                          className="focus:ring-base h-4 w-4 text-base border-gray-300"
                        />
                        <label
                          htmlFor="caisse2"
                          className="ml-3 mr-3 block text-sm font-medium text-gray-700"
                        >
                          Nu
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        update()
                        setCurrentField(null)
                        setShowForm((prev) => {
                          const updated = [...prev]
                          updated[index] = false
                          return updated
                        })
                      }}
                      className="inline-flex items-center px-6 py-2 border border-transparent font-medium rounded-full shadow-sm text-white bg-base hover:bg-base focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base"
                    >
                      Enregister le légume
                    </button>
                  </div>
                </div>
              </div>
            )
          })}

          {showForm.every((value) => value !== true) && (
            <div
              onClick={() => {
                append({})
                setCurrentField(fields.length)
                console.log(fields.length, showForm)
                setShowForm((prev) => {
                  return [...prev, true]
                })
              }}
              className="cursor-pointer mt-6 rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-8 px-8 bg-green-700 text-center text-white font-bold"
            >
              <span className="relative">
                + Ajouter un légume au bon de livraison
              </span>
            </div>
          )}
        </div>

        <StepBottom
          handlePrev={() => prevStep()}
          handleNext={handleSubmit(onSubmit, onError)}
        />
      </form>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

const BtBig = ({ icon, title, classCustom, titleColor, mode }) => {
  if(!mode) {
    return (
      <div
        className={`rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-8 px-8 ${classCustom}`}
      >
        <img src={icon} alt='Icône'className="w-16" />
        <h3 className={` ${!titleColor ? "text-white": titleColor} text-xs uppercase mt-3`}>{title}</h3>
      </div>
    )
  } else {
    return (
      <div
        className={`rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-2 px-2 ${classCustom}`}
      >
        <img src={icon} alt='Icône' />
        <h3 className={` ${!titleColor ? "text-white": titleColor} text-xs uppercase mt-3`}>{title}</h3>
      </div>
    )
  }
}

BtBig.propTypes = {
  titleColor: PropTypes.string,
  mode: PropTypes.string,
  classCustom: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default BtBig

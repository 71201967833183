import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  useMatch,
  useLocation,
  Link,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axios from 'axios'
// Store
import { Context } from '../store/Store'
// K

import Subtitle from '../components/Subtitle'
import Title from '../components/Title'
import { useQuery, getNumberOfWeek, getlegumes } from '../utils'
// Icones
import { BsArrowBarLeft, BsFileEarmarkArrowDown } from 'react-icons/bs'
// BT
import Header from '../components/Header'
import Footer from '../components/Footer'

export function LegumesList() {
  const { app, dispatch } = React.useContext(Context)
  const [produits, setProduits] = React.useState([])

  useEffect(() => {
    setProduits(
      app.fiches.map((legume) => {
        return { ...legume, show: false }
      })
    )
  }, [app.fiches])

  const _filterList = (v) => {
    let updatedList = app.fiches
    console.log(produits)
    updatedList = updatedList.filter(function (item) {
      return item.name.toLowerCase().search(v.toLowerCase()) !== -1
    })
    setProduits(updatedList)
  }

  return (
    <div className='flex h-full flex-col justify-between'>
      <Header
        left={
          <Link
            to='/'
            className='flex justify-center items-center w-7 h-7 rounded-full bg-white'
          >
            <BsArrowBarLeft />
          </Link>
        }
        right={<></>}
        title="Fiches produits"
      />

      <div className='overflow-auto h-full md:pl-64 '>
        <div className='max-w-7xl mx-auto px-6'>
          {produits && (
            <div className='mx-auto pb-10 pt-6'>
              <div className='flex-1 flex'>
                <form className='w-full flex md:ml-0' action='#' method='GET'>
                  <label htmlFor='search_field' className='sr-only'>
                    Chercher
                  </label>
                  <div className='relative w-full text-gray-400 focus-within:text-gray-600 radius-md bg-gray-300 px-5 py-2 mb-4 rounded-lg'>
                    <div className='absolute inset-y-0 left-8 flex items-center pointer-events-none'>
                      <svg
                        className='h-5 w-5'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          fillRule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </div>
                    <input
                      onKeyUp={(e) => _filterList(e.target.value)}
                      id='search_field'
                      name='search_field'
                      className='block w-full h-full pl-9 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 text-sm bg-transparent'
                      placeholder='Rechercher un légume'
                      type='search'
                    />
                  </div>
                </form>
              </div>
              <div>
                <Subtitle title='A télécharger' />
                <Title title='Fiches produits' />

                <div className='py-5 '>
                  <ul className='divide-y divide-gray-200 grid grid-cols-2 gap-4'>
                    {produits &&
                      produits.map((produit) => {
                        if (!produit) return

                        return (
                          <a
                            target='_blank'
                            href={`https://extranet.biobreizh.org/g/export.php?file=${produit.id}`}
                            className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                          >
                            <div className='rounded-lg shadow-lg col-span-1 flex flex-col justify-center items-center py-5 px-5 '>
                              {produit.url && (
                                <img
                                  className='h-20 w-20 rounded-full'
                                  src={`data:image/jpeg;base64,${produit.encoded}`}
                                  alt=''
                                />
                              )}
                              {!produit.url && (
                                <BsFileEarmarkArrowDown size={30} />
                              )}
                              <span className='text-center mt-2'>
                                {produit.name}
                              </span>
                            </div>
                          </a>
                        )
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  )
}

import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axios from 'axios'

// Store
import { Context } from '../store/Store'
// K
import Subtitle from '../components/Subtitle'
import InputSearch from '../components/InputSearch'
// Icones
const Legume = ({ legume, displayMode }) => {
  return (
    <div className='rounded-lg border bg-white border-gray-300 shadow-sm  hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-base'>
      <div className='relative px-6 py-2 flex items-center space-x-3'>
        <div className='flex-shrink-0'>
          <img
            className='h-10 w-10 rounded-full'
            src='/images/favicon-biobreizh.png'
            alt=''
          />
        </div>
        <div className='flex-1 min-w-0'>
          <p className='text-sm font-medium text-gray-900 flex flex-between w-full'>
            {legume.title}
          </p>
        </div>
        {displayMode === 'add' ? (
          <span className='flex justify-center items-center w-7 h-7 rounded-full text-white bg-base'>
            +
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

const Legumes = ({ handleLegume, displayMode }) => {
  const { app } = React.useContext(Context)
  const [legumes, setLegumes] = React.useState([])

  useEffect(() => {
    setLegumes(
      app.legumes.map((legume) => {
        return { ...legume, show: false }
      })
    )
  }, [app.legumes])

  const _filterList = (v) => {
    let updatedList = app.legumes
    updatedList = updatedList.filter(function (item) {
      return item.title.toLowerCase().search(v.toLowerCase()) !== -1
    })
    setLegumes(updatedList)
  }

  return (
    <div className='mx-auto pb-10 pt-6'>
      <InputSearch filterableList={app.legumes} handleFilter={setLegumes} searchField="title" placeHolder="Rechercher un légume" />

      <div>
        <div className='mt-1 grid grid-cols-1 gap-2 sm:grid-cols-2'>
          {legumes &&
            legumes.map((legume) => {
              return (
                <div
                  className='cursor-pointer'
                  onClick={() => handleLegume(legume)}
                >
                  <Legume displayMode={displayMode} legume={legume} />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

Subtitle.propTypes = {
  handleLegume: PropTypes.func,
}

export default Legumes

import {
  BrowserRouter as Router,
  useMatch,
  useLocation,
  Link,
} from 'react-router-dom'
import axios from 'axios'
import * as ACTIONS from './store/actions/actions'

const filterListLegumes = (app, v) => {
  let updatedList = app.legumes
  updatedList = updatedList.filter(function (item) {
    return item.title.toLowerCase().search(v.toLowerCase()) !== -1
  })
  return updatedList
}

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const getNumberOfWeek = (tdt = null) => {
  var dt = new Date()
  var tdt = new Date(dt.valueOf())
  var dayn = (dt.getDay() + 6) % 7
  tdt.setDate(tdt.getDate() - dayn + 3)
  var firstThursday = tdt.valueOf()
  tdt.setMonth(0, 1)
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7))
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000)
}

export const getlegumes = (app, dispatch) => {
  const user = window.localStorage.getItem('user')
  let u = JSON.parse(user)
  axios
    .get(
      `https://extranet.biobreizh.org/wp-json/bb/v1/legumesByProducteur/${u.id}`
    )
    .then(function (response) {
      if (response.status === 200) {
        dispatch(ACTIONS.setLegumes(response.data.legumes))
      }
    })
    .catch(function (error) {
      console.log(error)
    })
}

// Store
import { Context } from '../store/Store'
import { Link } from 'react-router-dom'
import Notifications from './Notifications'
import React from 'react'

const Footer = () => {
  const { app, dispatch } = React.useContext(Context)

  const renderNotification = () => {
    if (!app.notifications) return
    if (app.notifications.some((n) => n.subject === 'messages'))
      return <Notifications />
  }

  return (
    <footer className="bg-white shadow-footer2 w-full relative z-50">
      <div className="p-5">
        <div className="flex justify-between md:hidden">
          <Link className="text-gray-400 hover:text-gray-500" to="/">
            <span className="sr-only">Accueil</span>
            <svg
              id="Groupe_346"
              data-name="Groupe 346"
              xmlns="http://www.w3.org/2000/svg"
              width="36.727"
              height="36.727"
              viewBox="0 0 36.727 36.727"
            >
              <g id="bright_house" data-name="bright house">
                <g id="Groupe_328" data-name="Groupe 328">
                  <g id="Groupe_326" data-name="Groupe 326">
                    <path
                      id="Tracé_3975"
                      data-name="Tracé 3975"
                      d="M442.153,133.363h-4.017a.574.574,0,0,1-.574-.574v-2.573l-13.2-9.349-13.2,9.349v2a.574.574,0,0,1-.574.574h-4.017a.574.574,0,0,1-.574-.574V128.2a.573.573,0,0,1,.242-.468l17.79-12.625a.572.572,0,0,1,.665,0l17.79,12.625a.573.573,0,0,1,.242.468v4.591A.574.574,0,0,1,442.153,133.363Zm-3.443-1.148h2.869V128.5l-17.216-12.218L407.148,128.5v3.146h2.869V129.92a.574.574,0,0,1,.242-.468l13.773-9.756a.574.574,0,0,1,.663,0l13.773,9.756a.574.574,0,0,1,.242.468Z"
                      transform="translate(-406 -115)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_327"
                    data-name="Groupe 327"
                    transform="translate(24.676 0)"
                  >
                    <path
                      id="Tracé_3976"
                      data-name="Tracé 3976"
                      d="M456.46,123.034a.574.574,0,0,1-.574-.574v-6.312h-5.739V117.3a.574.574,0,1,1-1.148,0v-1.722a.574.574,0,0,1,.574-.574h6.886a.574.574,0,0,1,.574.574v6.886A.574.574,0,0,1,456.46,123.034Z"
                      transform="translate(-449 -115)"
                      fill="#552d32"
                    />
                  </g>
                </g>
                <g
                  id="Groupe_329"
                  data-name="Groupe 329"
                  transform="translate(4.017 18.938)"
                >
                  <path
                    id="Tracé_3977"
                    data-name="Tracé 3977"
                    d="M441.119,165.79H413.574a.574.574,0,0,1-.574-.574V148h1.148v16.642h26.4V148.574h1.148v16.642A.574.574,0,0,1,441.119,165.79Z"
                    transform="translate(-413 -148)"
                    fill="#552d32"
                  />
                </g>
                <g
                  id="Groupe_334"
                  data-name="Groupe 334"
                  transform="translate(10.329 16.068)"
                >
                  <g id="Groupe_330" data-name="Groupe 330">
                    <path
                      id="Tracé_3978"
                      data-name="Tracé 3978"
                      d="M430.312,149.886h-5.739a.574.574,0,0,1-.574-.574v-5.739a.574.574,0,0,1,.574-.574h5.739a.574.574,0,0,1,.574.574v5.739A.574.574,0,0,1,430.312,149.886Zm-5.165-1.148h4.591v-4.591h-4.591Z"
                      transform="translate(-424 -143)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_331"
                    data-name="Groupe 331"
                    transform="translate(9.182)"
                  >
                    <path
                      id="Tracé_3979"
                      data-name="Tracé 3979"
                      d="M446.312,149.886h-5.739a.574.574,0,0,1-.574-.574v-5.739a.574.574,0,0,1,.574-.574h5.739a.574.574,0,0,1,.574.574v5.739A.574.574,0,0,1,446.312,149.886Zm-5.165-1.148h4.591v-4.591h-4.591Z"
                      transform="translate(-440 -143)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_332"
                    data-name="Groupe 332"
                    transform="translate(0 9.182)"
                  >
                    <path
                      id="Tracé_3980"
                      data-name="Tracé 3980"
                      d="M430.312,165.886h-5.739a.574.574,0,0,1-.574-.574v-5.739a.574.574,0,0,1,.574-.574h5.739a.574.574,0,0,1,.574.574v5.739A.574.574,0,0,1,430.312,165.886Zm-5.165-1.148h4.591v-4.591h-4.591Z"
                      transform="translate(-424 -159)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_333"
                    data-name="Groupe 333"
                    transform="translate(9.182 9.182)"
                  >
                    <path
                      id="Tracé_3981"
                      data-name="Tracé 3981"
                      d="M446.312,165.886h-5.739a.574.574,0,0,1-.574-.574v-5.739a.574.574,0,0,1,.574-.574h5.739a.574.574,0,0,1,.574.574v5.739A.574.574,0,0,1,446.312,165.886Zm-5.165-1.148h4.591v-4.591h-4.591Z"
                      transform="translate(-440 -159)"
                      fill="#552d32"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </Link>

          <Link
            className="text-gray-400 hover:text-gray-500 relative"
            to="/messages"
          >
            <span className="sr-only">Messages</span>
            <svg
              id="Groupe_347"
              data-name="Groupe 347"
              xmlns="http://www.w3.org/2000/svg"
              width="36.724"
              height="36.727"
              viewBox="0 0 36.724 36.727"
            >
              <g
                id="letter_mailing_messaging_email"
                data-name="letter/mailing/messaging/email"
              >
                <g
                  id="Groupe_335"
                  data-name="Groupe 335"
                  transform="translate(4.017 4.02)"
                >
                  <path
                    id="Tracé_3982"
                    data-name="Tracé 3982"
                    d="M3774.122,1589.071a.574.574,0,0,1-.574-.574v-13.771a.575.575,0,0,0-.574-.574h-25.248a.575.575,0,0,0-.574.574V1588.5a.574.574,0,1,1-1.147,0v-13.771a1.723,1.723,0,0,1,1.721-1.721h25.248a1.723,1.723,0,0,1,1.721,1.721V1588.5A.573.573,0,0,1,3774.122,1589.071Z"
                    transform="translate(-3746.005 -1573.005)"
                    fill="#552d32"
                  />
                </g>
                <g id="Groupe_339" data-name="Groupe 339">
                  <g
                    id="Groupe_336"
                    data-name="Groupe 336"
                    transform="translate(0 21.234)"
                  >
                    <path
                      id="Tracé_3983"
                      data-name="Tracé 3983"
                      d="M3757.368,1612.377a4.008,4.008,0,0,1-1.75-.4l-16.289-7.881a.574.574,0,1,1,.5-1.033l16.289,7.881a2.851,2.851,0,0,0,2.5,0l16.289-7.881a.574.574,0,1,1,.5,1.033l-16.289,7.881A4.011,4.011,0,0,1,3757.368,1612.377Z"
                      transform="translate(-3739.005 -1603.002)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_337"
                    data-name="Groupe 337"
                    transform="translate(0 7.893)"
                  >
                    <path
                      id="Tracé_3984"
                      data-name="Tracé 3984"
                      d="M3772.86,1608.588h-30.986a2.873,2.873,0,0,1-2.869-2.869v-22.952a.575.575,0,0,1,.383-.542l3.909-2.387a.574.574,0,0,1,.6.98l-3.742,2.285v22.616a1.724,1.724,0,0,0,1.721,1.722h30.986a1.724,1.724,0,0,0,1.722-1.722V1583.1l-3.742-2.285a.574.574,0,0,1,.6-.98l3.908,2.387a.574.574,0,0,1,.383.542v22.952A2.873,2.873,0,0,1,3772.86,1608.588Z"
                      transform="translate(-3739.005 -1579.754)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_338"
                    data-name="Groupe 338"
                    transform="translate(10.254)"
                  >
                    <path
                      id="Tracé_3985"
                      data-name="Tracé 3985"
                      d="M3772.515,1571.167a.569.569,0,0,1-.3-.085l-5.737-3.51a2.857,2.857,0,0,0-3,0l-5.737,3.51a.574.574,0,0,1-.6-.979l5.737-3.51a4,4,0,0,1,4.193,0l5.737,3.51a.574.574,0,0,1-.3,1.064Z"
                      transform="translate(-3756.874 -1566)"
                      fill="#552d32"
                    />
                  </g>
                </g>
                <g
                  id="Groupe_343"
                  data-name="Groupe 343"
                  transform="translate(10.903 9.758)"
                >
                  <g id="Groupe_340" data-name="Groupe 340">
                    <path
                      id="Tracé_3986"
                      data-name="Tracé 3986"
                      d="M3765.464,1584.152h-6.886a.574.574,0,0,1,0-1.148h6.886a.574.574,0,0,1,0,1.148Z"
                      transform="translate(-3758.004 -1583.004)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_341"
                    data-name="Groupe 341"
                    transform="translate(0 4.59)"
                  >
                    <path
                      id="Tracé_3987"
                      data-name="Tracé 3987"
                      d="M3772.349,1592.151h-13.771a.574.574,0,0,1,0-1.148h13.771a.574.574,0,0,1,0,1.148Z"
                      transform="translate(-3758.004 -1591.003)"
                      fill="#552d32"
                    />
                  </g>
                  <g
                    id="Groupe_342"
                    data-name="Groupe 342"
                    transform="translate(0 9.181)"
                  >
                    <path
                      id="Tracé_3988"
                      data-name="Tracé 3988"
                      d="M3772.349,1600.15h-13.771a.574.574,0,0,1,0-1.148h13.771a.574.574,0,0,1,0,1.148Z"
                      transform="translate(-3758.004 -1599.002)"
                      fill="#552d32"
                    />
                  </g>
                </g>
              </g>
            </svg>
            {app.notifications.length > 0 && renderNotification()}
          </Link>

          <Link className="text-gray-400 hover:text-gray-500" to="/profil">
            <span className="sr-only">Profil</span>
            <svg
              id="Groupe_348"
              data-name="Groupe 348"
              xmlns="http://www.w3.org/2000/svg"
              width="36.727"
              height="36.727"
              viewBox="0 0 36.727 36.727"
            >
              <g id="male">
                <g id="Groupe_344" data-name="Groupe 344">
                  <path
                    id="Tracé_3989"
                    data-name="Tracé 3989"
                    d="M85.364,151.727a18.364,18.364,0,1,1,18.364-18.364A18.384,18.384,0,0,1,85.364,151.727Zm0-35.579a17.216,17.216,0,1,0,17.216,17.216A17.235,17.235,0,0,0,85.364,116.148Z"
                    transform="translate(-67 -115)"
                    fill="#464646"
                  />
                </g>
                <g
                  id="Groupe_345"
                  data-name="Groupe 345"
                  transform="translate(8.034 6.886)"
                >
                  <path
                    id="Tracé_3990"
                    data-name="Tracé 3990"
                    d="M101.086,148.233a.637.637,0,0,1-.066,0c-.933-.108-5.639-.7-6.682-1.918-.652-.759-.3-2.613-.123-3.375a.574.574,0,0,1,.3-.382c.884-.442,1.7-2.392,1.994-3.636.217-.917.389-2.432.515-3.538l.048-.412a9.642,9.642,0,0,0,0-2.727c-.314-2.333-2.783-4.093-5.744-4.093s-5.43,1.76-5.744,4.093a9.642,9.642,0,0,0,0,2.727l.048.412c.126,1.106.3,2.621.515,3.539.3,1.244,1.11,3.194,1.994,3.635a.574.574,0,0,1,.3.382c.18.762.529,2.617-.123,3.375-1.043,1.213-5.749,1.81-6.682,1.918a.574.574,0,0,1-.131-1.141c2.134-.246,5.389-.881,5.943-1.525a4.419,4.419,0,0,0-.072-2.129c-1.252-.849-2.076-3.108-2.347-4.251a37.031,37.031,0,0,1-.539-3.674l-.046-.41a10.755,10.755,0,0,1,0-3.012c.39-2.9,3.348-5.088,6.881-5.088s6.491,2.188,6.881,5.088a10.755,10.755,0,0,1,0,3.012l-.046.41a36.989,36.989,0,0,1-.539,3.673c-.271,1.143-1.095,3.4-2.347,4.251a4.439,4.439,0,0,0-.072,2.13c.553.643,3.808,1.279,5.942,1.524a.574.574,0,0,1-.065,1.144Z"
                    transform="translate(-81 -127)"
                    fill="#464646"
                  />
                </g>
              </g>
            </svg>
          </Link>
        </div>
        <div className="hidden md:block text-center">
          Biobreizh {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer

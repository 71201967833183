import * as ACTION_TYPES from './action_types'

export const logued = (val) => {
  return { type: ACTION_TYPES.LOGIN, payload: val }
}
export const refresh = (val) => {
  return { type: ACTION_TYPES.REFRESH, payload: val }
}
export const setMessages = (datas) => {
  return { type: ACTION_TYPES.SET_MESSAGES, payload: datas }
}
export const setNotifications = (datas) => {
  return { type: ACTION_TYPES.SET_NOTIFICATIONS, payload: datas }
}
export const setNews = (datas) => {
  return { type: ACTION_TYPES.SET_NEWS, payload: datas }
}
export const setDocs = (datas) => {
  return { type: ACTION_TYPES.SET_DOCS, payload: datas }
}
export const setBiobreizh = (datas) => {
  return { type: ACTION_TYPES.SET_BIOBREIZH, payload: datas }
}
export const setFiches = (datas) => {
  return { type: ACTION_TYPES.SET_FICHES, payload: datas }
}
export const setPrice = (datas) => {
  return { type: ACTION_TYPES.SET_PRICE, payload: datas }
}
export const setClients = (datas) => {
  return { type: ACTION_TYPES.SET_CLIENTS, payload: datas }
}
export const setAdherents = (datas) => {
  return { type: ACTION_TYPES.SET_ADHERENTS, payload: datas }
}
export const setProfil = (datas) => {
  return { type: ACTION_TYPES.SET_PROFIL, payload: datas }
}
export const setLegumes = (datas) => {
  return { type: ACTION_TYPES.SET_LEGUMES, payload: datas }
}
export const setLegumesHa = (datas) => {
  return { type: ACTION_TYPES.SET_LEGUMESHA, payload: datas }
}
export const setAllLegumes = (datas) => {
  return { type: ACTION_TYPES.SET_ALL_LEGUMES, payload: datas }
}
export const setPlanification = (datas) => {
  return { type: ACTION_TYPES.SET_PLANIFICATION, payload: datas }
}
export const setBl = (datas) => {
  return { type: ACTION_TYPES.SET_ADD_BL, payload: datas }
}
export const setMenuOpen = (datas) => {
  return { type: ACTION_TYPES.SET_MENU_OPEN, payload: datas }
}
export const setTokenNotifs = (datas) => {
  return { type: ACTION_TYPES.SET_TOKEN_NOTIFS, payload: datas }
}
export const setPo = (datas) => {
  return { type: ACTION_TYPES.SET_PO, payload: datas }
}
export const setTooltip = (datas) => {
  return { type: ACTION_TYPES.SET_TOOLTIP, payload: datas }
}
export const setPoTextes = (datas) => {
  return { type: ACTION_TYPES.SET_PO_TEXTES, payload: datas }
}
export const setPoFormState = (datas) => {
  return { type: ACTION_TYPES.SET_POFORMSTATE, payload: datas }
}
export const setBlsFormState = (datas) => {
  return { type: ACTION_TYPES.SET_BLSFORMSTATE, payload: datas }
}
export const setPoArchives = (datas) => {
  return { type: ACTION_TYPES.SET_POARCHIVES, payload: datas }
}
export const setQualite = (datas) => {
  return { type: ACTION_TYPES.SET_QUALITE, payload: datas }
}

import React, { useEffect, useState, useMemo } from 'react'
import {
  BrowserRouter as Router,
  useMatch,
  useLocation,
  Link,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axios from 'axios'
// K
import KAlert from '../../components/KAlert'
import { useQuery } from '../../utils'
import InputSearch from '../../components/InputSearch'
// Store
import { Context } from '../../store/Store'

const Legume = ({ legume, open, children }) => {
  return (
    <div
      className={`rounded-lg border ${
        legume.planified === null ? 'bg-white' : 'bg-gray-200'
      } border-gray-300 shadow-sm  hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-base`}
    >
      <div className='relative px-6 py-2 flex items-center space-x-3'>
        <div className='flex-shrink-0'>
          <img
            className='h-10 w-10 rounded-full'
            src='/images/favicon-biobreizh.png'
            alt=''
          />
        </div>
        <div className='flex-1 min-w-0'>
          <span className='absolute inset-0' aria-hidden='true'></span>
          <p className='text-sm font-medium text-gray-900'>{legume.title}</p>
          {open && <div className='py-2'>{children}</div>}
        </div>
      </div>
    </div>
  )
}

export function PlanificationList() {
  const { app, dispatch } = React.useContext(Context)
  const [legumes, setLegumes] = React.useState([])
  const [success, setSuccess] = React.useState()
  const [legumeInfos, setLegumeInfos] = React.useState([])
  const [, setError] = React.useState(null)

  // Routing
  let query = useQuery()

  useEffect(() => {
    if (query.get('success')) {
      setSuccess(query.get('success'))
    }
  }, [])

  useEffect(() => {
    setLegumes(
      app.legumes.map((legume) => {
        return { ...legume, show: false }
      })
    )
  }, [app.legumes])

  const handleLegumeInfos = (legume) => {
    setLegumeInfos([])
    setSuccess()
    setLegumes((legumes) => {
      return legumes.map((item) => {
        if (item.id === legume.id) return { ...item, show: !item.show }
        else return { ...item, show: false }
      })
    })

    axios
      .get(
        `${process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'}/wp-json/bb/v1/producteur_planification/${legume.planified}`
      )
      .then(function (response) {
        if (response.status === 200) {
          setLegumeInfos(response.data)
        } else {
          setError('Erreur ...')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className='m-6'>
      {legumes && (
        <div className='mx-auto pb-10 pt-6'>
          <InputSearch filterableList={app.legumes} handleFilter={setLegumes} searchField="title" placeHolder="Rechercher un légume" />
          <div>
            <div className='mt-1 grid grid-cols-1 gap-2 sm:grid-cols-2'>
              {legumes &&
                legumes.map((legume) => {
                  if (legume.planified !== null) {
                    return (
                      <Link
                        state={{ legume: legume, mode: 'edit' }}
                        to='form'
                      >
                        <Legume legume={legume} />
                      </Link>
                    )
                  } else
                    return (
                      <Link state={{ legume: legume }} to='form'>
                        <Legume legume={legume} />
                      </Link>
                    )
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

import React, { useState } from 'react'
import axios from 'axios'

import { Oval } from 'react-loader-spinner'
import { Transition } from '@headlessui/react'
import { BsToggleOff, BsToggleOn } from 'react-icons/bs'

// Store
import { Context } from '../../store/Store'
import * as ACTIONS from '../../store/actions/actions'

// K
import Subtitle from '../../components/Subtitle'
import Title from '../../components/Title'
var _ = require('lodash')

export function BiobreizhDocuments() {
  const { app, dispatch } = React.useContext(Context)
  const [cat, setCat] = useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (app.biobreizh.documents) {
      let cat = []
      app.biobreizh.documents.map((folder, i) => {
        cat.push({ catname: folder.title, show: i === 1 ? false : false })
      })
      setCat(cat)
    }
  }, [])

  React.useEffect(() => {
    if (app.biobreizh.certificats === 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
          }/wp-json/bb/v1/biobreizh`
        )
        .then(function (response) {
          if (response.status === 200) {
            setLoading(false)
            let cat = []
            response.data.biobreizh.documents.map((folder, i) => {
              cat.push({ catname: folder.title, show: i === 1 ? false : false })
            })
            setCat(cat)
            dispatch(
              ACTIONS.setBiobreizh({
                ...app.biobreizh,
                cc: response.data.biobreizh.cc,
                referentiel: response.data.biobreizh.referentiel.files,
                certificats: response.data.biobreizh.certificats.files,
                documents: response.data.biobreizh.docs,
                forms: response.data.biobreizh.forms,
              })
            )
          } else {
            setLoading(false)
          }
        })
        .catch(function (error) {
          setLoading(false)
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const _changeCat = (o) => {
    let newcat = []
    cat.map((c) => {
      if (c.catname === o.catname) newcat.push(o)
      else newcat.push(c)
    })
    setCat(newcat)
  }

  return (
    <div className='mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8'>
      <div className='space-y-4'>
        {!loading ? (
          <div className='w-full bg-white p-6 border-gray-200 rounded-lg'>
            <div className='divide-y divide-gray-200 mb-4'>
              <div className='space-y-1'>
                <Subtitle title='Biobreizh' />
                <Title title='Documents' />

                <div className='py-5 grid gap-4'>
                  {cat !== null &&
                    app.biobreizh.documents.map((folder) => {
                      if (!folder) return
                      let isOpen = _.filter(cat, { catname: folder.title })
                      return (
                        <div className='mt-4'>
                          <div
                            onClick={() =>
                              _changeCat({
                                catname: folder.title,
                                show: !isOpen[0].show,
                              })
                            }
                            className='border-b flex justify-between'
                          >
                            <h3>{folder.title}</h3>
                            {isOpen[0].show ? (
                              <BsToggleOn size={25} />
                            ) : (
                              <BsToggleOff size={25} />
                            )}
                          </div>

                          <Transition
                            show={isOpen[0].show}
                            enter-active-class='transition-opacity duration-75'
                            enter-from-class='opacity-0'
                            enter-to-class='opacity-100'
                            leave-active-class='transition-opacity duration-150'
                            leave-from-class='opacity-100'
                            leave-to-class='opacity-0'
                          >
                            {folder.files && folder.files.map((ref) => {
                              if (!ref) return
                              return (
                                <div className='flex rounded-md shadow mb-2'>
                                  <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={`${
                                      process.env.REACT_APP_APOLLO_URL ||
                                      'https://extranet.biobreizh.org'
                                    }/g/export.php?file=${ref.id}`}
                                    className='flex-1 items-center justify-center px-5 py-3 border border-transparent text-white font-medium rounded-md text-white bg-base hover:bg-base-light'
                                  >
                                    {ref.name}
                                  </a>
                                </div>
                              )
                            })}
                          </Transition>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center h-screen'>
            <Oval type='Oval' color='#48BFAD' height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  )
}

import * as ACTIONS from '../../store/actions/actions'

import { Controller, useForm } from 'react-hook-form'

import Bt from '../../components/Bt'
import { Context } from '../../store/Store'
import React from 'react'
import Select from 'react-select'
import { StepBottom } from './StepBottom'

export const Step1 = ({ nextStep, prevStep }) => {
  const { app, dispatch } = React.useContext(Context)
  const clients = app.clients
  const { register, handleSubmit, watch, control, reset } = useForm({
    defaultValues: app.blsFormState?.step1,
  })

  console.log('STEP1 preload', app.blsFormState)

  const onSubmit = (data) => {
    dispatch(ACTIONS.setBlsFormState({ ...app.blsFormState, step1: data }))
    reset()
    nextStep()
  }

  const showPalettePlus = watch(
    'palette',
    app.blsFormState?.step1?.palette || false
  )

  return (
    <div className="max-w-7xl mx-auto p-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-gray-200 pt-5">
          <label
            htmlFor="cert1"
            className="mr-3 block text-sm font-medium text-gray-700"
          >
            Client <span className="text-red-500">*</span>
          </label>

          <Controller
            control={control}
            label="Client"
            name="client"
            rules={{
              required: 'Veuillez préciser le client',
            }}
            render={({ field }) => (
              <Select
                {...field}
                options={clients}
                isSearchable={true}
                placeholder="Client ..."
              />
            )}
          />
        </div>
        <div className="py-4">
          <h2
            className={`text-l font-bold tracking-tight text-gray-900 mt-4 mb-4 border-b-2 pb-2 max-w-lg`}
          >
            Consignes
          </h2>
          <div className="flex items-center">
            <input
              id="palette1"
              type="radio"
              {...register('palette', { required: true })}
              value="Pas de consignes"
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label
              htmlFor="palette1"
              className="ml-3 mr-3 block text-sm font-medium text-gray-700"
            >
              Pas de consignes
            </label>
            <input
              id="palette2"
              type="radio"
              {...register('palette', { required: true })}
              value="Palette 80 x 120"
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label
              htmlFor="palette2"
              className="ml-3 mr-3 block text-sm font-medium text-gray-700"
            >
              Palette 80 x 120
            </label>
            <input
              id="palette3"
              type="radio"
              {...register('palette', { required: true })}
              value="Palette 100 x 120"
              className="focus:ring-base h-4 w-4 text-base border-gray-300"
            />
            <label
              htmlFor="palette3"
              className="ml-3 mr-3 block text-sm font-medium text-gray-700"
            >
              Palette 100 x 120 <span className="text-red-500">*</span>
            </label>
          </div>
          {(showPalettePlus === 'Palette 80 x 120' ||
            showPalettePlus === 'Palette 100 x 120') && (
            <div className="border-gray-200 pt-5">
              <label
                htmlFor="qty_palette"
                className="block text-sm font-medium text-gray-700"
              >
                Quantité palettes<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                placeholder={0}
                id="qty_palette"
                {...register('qty_palette', { required: true })}
                className="max-w-lg block w-full shadow-sm focus:ring-base focus:border-base border-gray-300 rounded-md"
              />
            </div>
          )}
        </div>
        <div className="py-4">
          <label
            htmlFor="about"
            className="block text-sm font-medium text-gray-700"
          >
            Commentaire
          </label>
          <div className="mt-1">
            <textarea
              id="comment"
              {...register('comment')}
              rows="3"
              className="max-w-lg shadow-sm block w-full focus:ring-base focus:border-base border-gray-300 rounded-md"
            ></textarea>
          </div>
        </div>

        <StepBottom
          handlePrev={() => prevStep()}
          handleNext={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  )
}

import * as ACTIONS from '../store/actions/actions'

import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

import { Context } from '../store/Store'

export default function Modal({ children }) {
  const { app, dispatch } = React.useContext(Context)

  return (
    <Transition show={app.tooltip !== null}>
      <Dialog
        className="relative z-50"
        onClose={() => dispatch(ACTIONS.setTooltip(null))}
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-2/3 m-auto sm:p-6">
                <button
                  onClick={() => dispatch(ACTIONS.setTooltip(null))}
                  className="bg-red-500 text-white rounded px-3 absolute right-2 top-2"
                >
                  Fermer
                </button>

                <img src={`/images/tooltip/${app.tooltip.src}`} />
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

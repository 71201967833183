import React from 'react'
import PropTypes from 'prop-types'

const BtClear = ({ handleClick }) => {
  return (
    <div className='cursor-pointer flex items-center justify-center h-8 w-8' onClick={handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-100  hover:text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
  )
}

BtClear.propTypes = {
  handleClick: PropTypes.func,
}

export default BtClear

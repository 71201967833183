// Styles
import './App.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import * as ACTIONS from './store/actions/actions'

// Thirds
import { Route, Routes } from 'react-router-dom'
import { fetchToken, onMessageListener } from './firebaseInit'
import { getNumberOfWeek, getlegumes, useQuery } from './utils'

import { AchatsCatalogue } from './screens/achats/AchatsCatalogue'
import { AchatsList } from './screens/achats/AchatsList'
import { Adherents } from './screens/adherents/Adherents'
import { AnnoncesForm } from './screens/AnnoncesForm'
import { AnnoncesList } from './screens/AnnoncesList'
import { Biobreizh } from './screens/biobreizh/Biobreizh'
import { BiobreizhCertificats } from './screens/biobreizh/BiobreizhCertificats'
import { BiobreizhDocuments } from './screens/biobreizh/BiobreizhDocuments'
import { BiobreizhFormOrganochlores } from './screens/biobreizh/BiobreizhFormOrganochlores'
import { BiobreizhFormsList } from './screens/biobreizh/BiobreizhFormsList'
import { Bls } from './screens/Bls'
// Store
import { Context } from './store/Store'
import { Docs } from './screens/Docs'
import { Form as FormBls } from './screens/bls/Form'
// K
import { Home } from './screens/Home'
import { LayoutAchat } from './screens/layouts/LayoutAchat'
import { LayoutBiobreizh } from './screens/layouts/LayoutBiobreizh'
import { LayoutCommon } from './screens/layouts/LayoutCommon'
import { LayoutForm } from './screens/layouts/LayoutForm'
import { LayoutPlanification } from './screens/layouts/LayoutPlanification'
import { LegumesList } from './screens/LegumesList'
import { Login } from './components/Login'
import { Messages } from './screens/Messages'
import Modal from './components/Modal'
import { News } from './screens/News'
import { NotationsForm } from './screens/NotationsForm'
import { NotationsList } from './screens/NotationsList'
import { PlanificationBilans } from './screens/planifications/PlanificationBilans'
import { PlanificationForm } from './screens/planifications/PlanificationForm'
import { PlanificationInfos } from './screens/planifications/PlanificationInfos'
import { PlanificationList } from './screens/planifications/PlanificationList'
import { PoArchives } from './screens/po/PoArchives'
import { PoForm } from './screens/po/PoForm'
import { PoHome } from './screens/po/PoHome'
import { PoHomePAV } from './screens/po/PoHomePAV'
import { PoHomePEC } from './screens/po/PoHomePEC'
import { Price } from './screens/Price'
import { Profil } from './screens/Profil'
import { QualiteHome } from './screens/qualite/QualiteHome'
import React from 'react'
import { Review as ReviewBls } from './screens/bls/Review'
import { Step1 as Step1Bls } from './screens/bls/Step1'
import { Step2 as Step2Bls } from './screens/bls/Step2'
import { Step3 as Step3Bls } from './screens/bls/Step3'
import { db } from './db'
import { useLiveQuery } from 'dexie-react-hooks'

//Store

function App() {
  const { app, dispatch } = React.useContext(Context)
  const user = window.localStorage.getItem('user')
  const notifs = useLiveQuery(() => db.notifs.toArray())

  onMessageListener()
    .then((payload) => {
      db.notifs.add({
        subject: payload.data.subject,
        title: payload.data.title,
        body: payload.data.body,
        new: true,
      })
    })
    .catch((err) => console.log('failed: ', err))

  // Notif
  React.useEffect(() => {
    fetchToken(app, dispatch)
    if (!notifs) return
    let refresh = false

    // DB
    db.notifs.toArray((allnotifs) => {
      let oldNotifications = app.notifications
      let newNotifications = [...oldNotifications, ...allnotifs]
      dispatch(ACTIONS.setNotifications(newNotifications))

      allnotifs.map((notif) => {
        if (notif.new) refresh = true

        db.notifs
          .where({
            id: notif.id,
          })
          .modify({ new: false })
      })

      if (refresh) {
        // Force le refresh de l'app pour récupérer les dernières datas
        // Note : je fait un gros refresh qui va chercher tout, pas ouf.
        // Ce serait bien de décomposer les appelles en fonction des besoin
        // Mais bon, faudrait revoir un peu le fonctionnement gobal
        document.location = '/'
      }
    })

    //return () => db.close()
  }, [notifs])

  return (
    <div className="h-screen">
      <Routes>
        <Route
          path="/planification"
          element={<LayoutPlanification title="Planification" />}
        >
          <Route index element={<PlanificationList />} />
          <Route path="form" element={<PlanificationForm />} />
          <Route path="bilans" element={<PlanificationBilans />} />
          <Route path="infos" element={<PlanificationInfos />} />
        </Route>
        <Route path="/price" element={<Price />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/news" element={<News />} />
        <Route path="/messages" element={<Messages />} />
        <Route
          path="/biobreizh"
          element={<LayoutBiobreizh title="Biobreizh" />}
        >
          <Route index element={<Biobreizh />} />
          <Route path="certificats" element={<BiobreizhCertificats />} />
          <Route path="documents" element={<BiobreizhDocuments />} />
          <Route path="formulaires" element={<BiobreizhFormsList />} />
          <Route
            path="organochlores"
            element={<BiobreizhFormOrganochlores />}
          />
        </Route>
        <Route
          path="/annonces"
          element={
            <LayoutForm title={`Annonces Sem. ${getNumberOfWeek() + 1}`} />
          }
        >
          <Route index element={<AnnoncesList />} />
          <Route path="annonce" element={<AnnoncesForm />} />
        </Route>
        <Route path="/profil" element={<Profil />} />
        <Route path="/fiches" element={<LegumesList />} />>
        <Route
          path="/bls"
          element={
            <LayoutForm
              title={`Bons de livraison SEM.${getNumberOfWeek() + 1}`}
            />
          }
        >
          <Route index element={<FormBls />} />
          <Route path="etape-1" element={<Step1Bls />} />
          <Route path="etape-2" element={<Step2Bls />} />
          <Route path="etape-3" element={<Step3Bls />} />
          <Route path="verification" element={<ReviewBls />} />
        </Route>
        <Route
          path="/qualite"
          element={<LayoutForm title="Contrôle qualité" />}
        >
          <Route index element={<QualiteHome />} />
        </Route>
        <Route path="/po" element={<LayoutForm title="Programmes d'aides" />}>
          <Route index element={<PoHome />} />
          <Route path="po-mes-demandes" element={<PoArchives />} />
          <Route path="po-home-pec" element={<PoHomePEC />} />
          <Route path="po-home-pav" element={<PoHomePAV />} />
          <Route path="po-form/:title/:mesure" element={<PoForm />} />
        </Route>
        <Route
          path="/notations"
          element={<LayoutForm title="Notations Hadenn" />}
        >
          <Route index element={<NotationsList />} />
          <Route path="notations-form" element={<NotationsForm />} />
        </Route>
        <Route path="/achats" element={<LayoutAchat title="Achats groupés" />}>
          <Route index element={<AchatsList />} />
          <Route path="catalogue" element={<AchatsCatalogue />} />
        </Route>
        <Route
          path="/adherents"
          element={<LayoutCommon title="Annuaire des adhérents" />}
        >
          <Route index element={<Adherents />} />
        </Route>
        <Route index element={user ? <Home /> : <Login />} />
        <Route path="*" element={<div className="p-6">Bientôt ...</div>} />
      </Routes>

      {app.tooltip && <Modal></Modal>}
    </div>
  )
}

export default App
